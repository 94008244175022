<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>Detalles de cobrador</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="d-block">
        <mat-label>
          Nombre
        </mat-label>
        <input disabled  [(ngModel)]="collector_name" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Apellido
        </mat-label>
        <input disabled [(ngModel)]="collector_lastname" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          DNI
        </mat-label>
        <input disabled [(ngModel)]="collector_dni" [ngModelOptions]="{standalone: true}"  matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Email
        </mat-label>
        <input disabled [(ngModel)]="collector_email" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Telefono
        </mat-label>
        <input disabled [(ngModel)]="collector_phonenumber" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <button mat-flat-button color="warn" [routerLink]="['/' + typeAdmin,'main','admin-collectors']">Volver</button>
    </mat-card-content>
  </mat-card>
</div>
<h1 class="mx-auto text-center mt-5">Clientes asociados</h1>
<button mat-fab color="primary"
        *ngIf="!showAddClient"
        (click)="showClientButton()"
        class="mx-auto d-block my-5"><mat-icon>add</mat-icon></button>
<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center my-5"
            *ngIf="showAddClient">
    <mat-card-content>
      <!-- <mat-form-field appearance="fill" class="d-block">
        <mat-label>Seleccione un cliente</mat-label>
        <mat-select [(ngModel)]="customer_dni" [ngModelOptions]="{standalone: true}">
          <mat-option [value]="customer.customer_dni" *ngFor="let customer of allCustomersData">{{ customer.customer_name }} {{ customer.customer_lastname }}</mat-option>
        </mat-select>
      </mat-form-field> -->
      <mat-form-field class="d-block d-lg-inline-block col-lg-6">
        <mat-label>
          Cliente
        </mat-label>
        <input type="text"
               matInput
               [formControl]="customerControl"
               [matAutocomplete]="auto">
       <mat-autocomplete #auto="matAutocomplete">
         <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.customer_id">
           {{ customer.customer_id }} - {{ customer.customer_name }} {{ customer.customer_lastname }}
         </mat-option>
       </mat-autocomplete>
       <mat-error *ngIf="customerControl.hasError('dni')">Debe seleccionar un cliente de la lista</mat-error>
      </mat-form-field>
      <span class="d-block d-lg-inline-block col-lg-6">{{ customerById[customerControl.value] }}</span>
      <button mat-flat-button color="primary" class="mx-2 mt-2" (click)="insertRelation()">Añadir</button>
      <button mat-flat-button color="warn" class="mx-auto mt-2" (click)="showClientButton()">Cancelar</button>
    </mat-card-content>
  </mat-card>
</div>
<h2 *ngIf="customersData.length <= 0"
    class="text-center">No hay clientes asignados</h2>
<div class="container">
    <div id="fancy-list-group">
      <div class="row">
          <div class="col-lg-12">
            <li class="list-group-item list-group-item-info" *ngFor="let customer of customersData">
              <div class="list-group-item-content">
                <h3 class="list-group-item-heading font-weight-bold">{{ customer.customer_name }} {{ customer.customer_lastname }}</h3>
                <p class="list-group-item-heading">ID: {{customer.customer_id}}</p>
              </div>
              <div class="list-group-item-controls">
                <span class="label mt-2">Acciones</span>
                <button mat-button
                        *ngIf="generalService.getUserType()  == typeAdmin"
                        class="custom-button-clients mx-2"
                        (click)="deleteRelation(customer?.customer_id)"><mat-icon>delete_outline</mat-icon></button>
                <button *ngIf="generalService.getUserType()  == typeAdmin" mat-button class="custom-button-clients mx-2" [routerLink]="['/' + typeAdmin,'main','client-detail',customer.customer_id]"><mat-icon>add</mat-icon></button>
              </div>
            </li>

          </div>
      </div>
    </div>
</div>
