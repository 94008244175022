import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  today : Date;
  day : Date;
  week : Date;
  month : Date;

  paid : number = -1;
  expected : number = -1;

  constructor(private generalService : GeneralService) {
    this.today = new Date();
    this.day = new Date();
    this.week = new Date();
    this.week.setDate(this.week.getDate() - 7);
    this.month = new Date();
    this.month.setMonth(this.month.getMonth() - 1);
  }

  loadToday(value : Date) : void {
    this.paid = -1;
    this.expected = -1;
    const start = this.generalService.formatDate(value);
    const end = this.generalService.formatDate(value);
    console.log(start, end);

    this.generalService.getInstallmentsByRange(start, end)
      .subscribe(response => {

      console.log(response);

      if (response[0] == false) {
        this.generalService.showMessage('Error en la carga de datos', '', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.paid = 0;
        this.expected = 0;
        return;
      }

      this.paid = response[1].reduce((total, current) => total + +current['installments_payment'], 0);
      this.expected = response[1].reduce((total, current) => total + +current['installments_debt'], 0);
    });
  }

  loadWeek() : void {
    this.paid = -1;
    this.expected = -1;
    const start = this.generalService.formatDate(this.week);
    const end = this.generalService.formatDate(this.today);
    this.generalService.getInstallmentsByRange(start, end)
      .subscribe(response => {

      console.log(response);

      if (response[0] == false) {
        this.generalService.showMessage('Error en la carga de datos', '', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.paid = 0;
        this.expected = 0;
        return;
      }

      this.paid = response[1].reduce((total, current) => total + +current['installments_payment'], 0);
      this.expected = response[1].reduce((total, current) => total + +current['installments_debt'], 0);
    });
  }

  loadMonth() : void {
    this.paid = -1;
    this.expected = -1;
    const start = this.generalService.formatDate(this.month);
    const end = this.generalService.formatDate(this.today);
    this.generalService.getInstallmentsByRange(start, end)
      .subscribe(response => {

      console.log(response);

      if (response[0] == false) {
        this.generalService.showMessage('Error en la carga de datos', '', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.paid = 0;
        this.expected = 0;
        return;
      }

      this.paid = response[1].reduce((total, current) => total + +current['installments_payment'], 0);
      this.expected = response[1].reduce((total, current) => total + +current['installments_debt'], 0);
    });
  }

  ngOnInit() : void {
    this.loadToday(this.today);
  }

}
