import { Component, OnInit } from '@angular/core';
import { GeneralService, TYPE_COLLECTOR } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { Loan } from 'src/app/classes/loan';
import { Installment } from 'src/app/classes/installment';
@Component({
  selector: 'app-admin-loans',
  templateUrl: './admin-loans.component.html',
  styleUrls: ['./admin-loans.component.scss']
})
export class AdminLoansComponent implements OnInit {

  customerData : any = {};
  readonly typeCollector = TYPE_COLLECTOR ;

  debtcollector_dni : string;

  loan_date : Date = new Date();
  date_search : Date;
  date_complete: string;
  y = `${this.loan_date.getFullYear()}`;
  m = `${this.loan_date.getMonth() + 1}`;
  d = `${this.loan_date.getDate()}`;

  customer_name : string;
  customer_id : string;
  filter_clients : number;
  // day_list : number[] = [1, 2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
  day_list : number[];
  day : number;

  constructor(private generalService : GeneralService) {
    this.debtcollector_dni = this.generalService.getUserId();
    this.day_list = Array.from(Array(30).keys()).map(item => item + 1);
    this.loadCustomers();
  }

  loadCustomers() : void {
    this.generalService.getCustomerCollector(this.debtcollector_dni)
      .subscribe(response => {
      console.log(response);

      if (response[0] == false) {
        Swal.fire('No hay clientes asignados', '', 'info');
        return;
      }else{
        this.customerData = response[1];
      }
    });
  }
  cancel() : void {
    this.filter_clients = undefined;
    this.loadCustomers();
  }

  filterLoans() : void{
    this.generalService.getCustomerByName(this.customer_name, this.debtcollector_dni)
      .subscribe(response => {
      console.log(response);
      if (response[1] == "no result") {
        Swal.fire('Usuario no encontrado', '', 'info');
        return;
      }else{
        this.customerData = response[1];
      }

    });
  }


  filterDateLoans() : void {
    let y = `${this.date_search.getFullYear()}`;
    let m = `${this.date_search.getMonth() + 1}`;
    let d = `${this.date_search.getDate()}`;
    let order_date = `${y}-${(m.length < 2) ? '0' + m : m}-${(d.length < 2) ? '0' + d : d}`;
    console.log(order_date);

    this.generalService.getCustomerRegister(order_date, this.debtcollector_dni)
      .subscribe(response => {
      console.log(response);
      if (response[1] == "no result") {
        Swal.fire('No hay clientes para la fecha de registro seleccionada', '', 'info');
        return;
      } else {
        this.customerData = response[1];
      }

    });
  }

  filterPayday() : void {
    this.generalService.getCustomerPayday(this.day, this.debtcollector_dni)
      .subscribe(response => {
      console.log(response);
      if (response[1] == "no result") {
        Swal.fire('No hay clientes para el dia de cobro selecionado', '', 'info');
        return;
      }else{
        this.customerData = response[1];
      }

    });

  }

  filterClientID() : void {
    this.generalService.getCustomer(this.customer_id, this.debtcollector_dni)
      .subscribe(response => {
      console.log(response);
      if (response[1] == "no result") {
        Swal.fire('No hay clientes para el ID solicitado', '', 'info');
        return;
      }else{
        this.customerData = response[1];
      }

    });
  }


  ngOnInit(): void {
  }

}
