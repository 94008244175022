import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { AddPaymentDialogComponent } from './../../dialog/add-payment-dialog/add-payment-dialog.component';

import { TYPE_COLLECTOR, TYPE_ADMIN, GeneralService, SERVER_URL } from 'src/app/services/general.service';

import { Loan } from 'src/app/classes/loan';
import { Customer } from 'src/app/classes/customer';
import { Installment } from 'src/app/classes/installment';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  readonly typeCollector = TYPE_COLLECTOR ;
  readonly typeAdmin = TYPE_ADMIN ;
  userType : string;
  installments : Installment[] = [];
  paids : number[] = [];
  loansData : Loan[] = [];

  editing : boolean[] = []

  customerData : Customer;

  constructor(private generalService : GeneralService,
    private route: ActivatedRoute, private router : Router,
    private _location: Location, private dialog : MatDialog) {
    this.route.params.subscribe(params => {
      this.loadCustomer(params.id);
      this.loadInstallments(params.id);
    });
  }

  ngOnInit(): void {
    this.userType = this.generalService.getUserType();
  }
  back(){
   this._location.back();
  }

  public openAddDialog() : void {
    const dialogRef = this.dialog.open(AddPaymentDialogComponent, {
      disableClose: true,
      width: '100%',
      data: this.customerData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addShare(result, this.customerData.customer_id, this.customerData.customer_fee);
      }
    });
  }

  private addShare(total : number, customer_id : string, customer_fee : number) {
    const date : string = this.generalService.formatDate(new Date());
    this.generalService.registerInstallment(date, total, customer_id, customer_fee)
      .subscribe(response => {
      console.log(response);
      if (response[0] == true) {
        this.generalService.showMessage('Cuota pagada', '', 'success');
        this.loadInstallments(customer_id);
      } else {
        this.generalService.showMessage('No se pudo registrar el pago de la cuota', '', 'error');
      }
    });
  }

  loadCustomer(customer_id : string) : void {
    this.generalService.getCustomerById(customer_id).subscribe(response => {
      console.log(response);

      if (response[0] == false) {
        this.generalService.showMessage('Error inesperado', '', 'error');
        return;
      }

      if (response[1] == 'no result') {
        return;
      }

      this.customerData = response[1][0];
      this.generalService.getCustomerActiveLoan(customer_id).subscribe(response => {
        if (response[0] == false) {
          this.generalService.showMessage('Error inesperado', '', 'error');
          return;
        }

        if (response[1] == 'no result') {
          return;
        }

        this.customerData.loan = response[1];
      });
    });
  }

  loadInstallments(customer_id : string) : void {
    this.generalService.getInstallmentsById(customer_id).subscribe(response => {
      console.log(response);

      if (response[0] == false) {
        this.generalService.showMessage('Error inesperado', '', 'error');
        return;
      }

      if (response[1] == 'no result') {
        return;
      }

      this.installments = response[1].map(item => {
        item.installments_date = new Date(item.installments_date);
        item.installments_payment = parseFloat(item.installments_payment).toFixed(2);
        item.installments_debt = parseFloat(item.installments_debt).toFixed(2);
        return item;
      });
      this.editing = this.installments.map(item => false);
    });
  }

  goToPDF(id : number) : void {
    // const base_url = this.dataService.url_base;
    window
    .open(`${SERVER_URL}/service/generaloperations/generate_receipt.php?installments_id=${id}`, '_blank');
  }

  updateInstallment(index : number) : void {
    const installment = this.installments[index];
    const date = this.generalService.formatDate(installment.installments_date);
    console.log(date);

    this.generalService.updateInstallment(installment.installments_id,
      installment.installments_payment, date, installment.installments_debt)
      .subscribe(response => {

      console.log(response);

      if (response[0] == false) {
        this.generalService.showMessage('El pago no pudo ser actualizado', '', 'error');
        return;
      }

      this.generalService.showMessage('El pago fue actualizado correctamente', '', 'success');
      this.loadInstallments(this.customerData.customer_id);
      this.editing[index] = false;
    })
  }

}
