import { Component, OnInit } from '@angular/core';
import { TYPE_ADMIN, GeneralService } from 'src/app/services/general.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-collector',
  templateUrl: './edit-collector.component.html',
  styleUrls: ['./edit-collector.component.scss']
})
export class EditCollectorComponent implements OnInit {
  readonly typeAdmin = TYPE_ADMIN ;
  collector_dni : string;
  collector_name : string;
  collector_lastname : string;
  collector_email : string;
  collector_phonenumber : string;
  collector_password : string;
  r_password : string;

  constructor(public generalService: GeneralService,private route: ActivatedRoute) {
    this.route.params.subscribe(parametros => {
      this.loadCollector(parametros.id);
      this.collector_dni = parametros.id;
    });
  }
  loadCollector(collector_dni : string){
    this.generalService.getCollector(collector_dni).subscribe( responseCollector => {
      console.log(responseCollector);
      if(responseCollector[1] == "no result"){
        Swal.fire('Error en la carga de datos del cobrador','','error');
      }else{
        this.collector_name = responseCollector[1][0].debtcollector_name;
        this.collector_lastname = responseCollector[1][0].debtcollector_lastname;
        this.collector_email = responseCollector[1][0].debtcollector_email;
        this.collector_phonenumber = responseCollector[1][0].debtcollector_phonenumber;
      }
    })
  }
  updateCollector(){
    if(this.collector_password != this.r_password){
      Swal.fire('Las contraseñas no coinciden', '', 'error');
    }else{
      this.generalService.updateCollector(this.collector_name,this.collector_lastname,
      this.collector_dni,this.collector_phonenumber,this.r_password).subscribe(responseUpdate => {
        console.log(responseUpdate);
        if(responseUpdate[0]== true){
          Swal.fire('Cobrador modificado', '', 'success');
        }else{
          Swal.fire('No se pudo modificar el cobrador', '', 'error');
        }
      });
    }

  }

  ngOnInit(): void {
  }

}
