import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GeneralService,TYPE_ADMIN, SERVER_URL } from 'src/app/services/general.service';
import { Loan } from 'src/app/classes/loan';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {
  readonly typeAdmin = TYPE_ADMIN ;

  loanData : Loan[] = [];
  customer_id : string;
  customer_register : string;
  customer_dni : string;
  customer_name : string;
  customer_lastname : string;
  customer_direction : string;
  customer_email : string;
  customer_phonenumber : string;
  customer_fee : number;
  customer_debt : number;
  customer_paid : number;

  showFeeForm : boolean = false;
  feeGroup : FormGroup;

  constructor(public generalService : GeneralService, private route : ActivatedRoute,
    private _location : Location, private formBuilder : FormBuilder) {

    this.feeGroup = this.formBuilder.group({
      fee: [0],
      message: ['']
    });

    this.route.params.subscribe(parametros => {
      this.loadClient(parametros.id);
      this.loadClientLoans(parametros.id);
      this.customer_id = parametros.id;
    });
  }

  loadClient(customer_id : string) {
    console.log(customer_id);

    this.generalService.getCustomer(customer_id).subscribe( responseCustomer => {
      console.log(responseCustomer);
      if (responseCustomer[1] == "no result") {
        Swal.fire('Error en la carga de datos del cliente','','error');
      } else {
        this.customer_register = responseCustomer[1][0].customer_register;
        this.customer_id = responseCustomer[1][0].customer_id;
        this.customer_dni = responseCustomer[1][0].customer_dni;
        this.customer_name = responseCustomer[1][0].customer_name;
        this.customer_lastname = responseCustomer[1][0].customer_lastname;
        this.customer_direction = responseCustomer[1][0].customer_direction;
        this.customer_email = responseCustomer[1][0].customer_email;
        this.customer_phonenumber = responseCustomer[1][0].customer_phonenumber;
        this.customer_fee = responseCustomer[1][0].customer_fee;

        this.customer_debt = responseCustomer[1][0].customer_debt;
        this.customer_paid = responseCustomer[1][0].customer_paid;

        responseCustomer[1][0].fee_ammount_visual = responseCustomer[1][0].fee_ammount;

        this.feeGroup.reset(responseCustomer[1][0]);
      }
    });
  }
  loadClientLoans(customer_id : string){
    this.generalService.getLoan(customer_id).subscribe( responseLoans => {
      console.log(responseLoans)
      if (responseLoans[1] == "no result") {
        // Swal.fire('El cliente no ha generado compras','','info');
      } else {
        this.loanData = responseLoans[1];
        this.loadClientsName();
      }
    });
  }
  loadClientsName() : void {
    for (let i in this.loanData) {
      this.generalService.getCustomer(this.loanData[i].customer_id).subscribe(responseCustomer => {
        if (responseCustomer[1][0] != null) {
          this.loanData[i].customer_name = `${responseCustomer[1][0].customer_name} ${responseCustomer[1][0].customer_lastname}`;
        }
      });
    }
  }
  back(){
   this._location.back();
  }

  updateFee() : void {
    if (this.feeGroup.invalid) {
      this.generalService.showMessage('Monto de cuota invalido', '', 'error');
      return;
    }

    const value = this.feeGroup.value;

    this.generalService.updateFee(this.feeGroup.value, this.customer_id).subscribe(response => {
      if (response[0] == false) {
        this.generalService.showMessage('Error inesperado al actualizar cuota', '', 'error');
        return;
      }

      this.generalService.showMessage('Se ha actualizado la cuota', '', 'success');
      console.log(this.customer_dni);

      this.loadClient(this.customer_id);
    });
  }

  ngOnInit(): void {
  }

  goToPDF() : void {
    window
    .open(`${SERVER_URL}/service/generaloperations/generate_summary.php?customer_id=${this.customer_id}`, '_blank');
  }

}
