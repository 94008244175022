<div class="clearfix"><br /></div>
<div class="container">
    <div id="fancy-list-group">
      <div class="row">
          <div class="col-lg-12">

            <h1 class="mx-auto text-center" *ngIf="actualPayCustomerData.length != 0">Cobros realizados hoy</h1>
            <div *ngFor="let customer of actualPayCustomerData, let i = index">
              <li class="list-group-item list-group-item-info">
                <div class="list-group-item-content">

                  <!-- Total deuda, total pagado, restante adeudado -->
                  <span class="float-right text-left font-weight-bold">
                    <p class="text-danger">Deuda total: {{ (customer?.customer_debt) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                    <p class="text-success">Total pagado: {{ (customer?.customer_paid) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                    <p class="text-secondary">Pendiente: {{ (customer?.customer_debt - customer?.customer_paid) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                  </span>
                  <p class="list-group-item-heading">Nombre del cliente: {{ customer.customer_name }} {{ customer.customer_lastname }}</p>
                  <p class="list-group-item-heading">ID del cliente: {{ customer.customer_id}}</p>
                  <p class="list-group-item-heading">Direccion: {{ customer.customer_direction }}</p>
                  <p class="list-group-item-heading">Telefono: {{ customer.customer_phonenumber }}</p>
                  <mat-divider  [inset]="true"
                                class="my-3"
                                *ngIf="customer?.loan?.length > 0">
                  </mat-divider>
                  <h3   class="mx-2"
                        *ngIf="customer?.loan?.length > 0">Conceptos</h3>
                  <ul *ngIf="customer?.loan?.length > 0">
                    <li *ngFor="let loan of customer?.loan">
                      {{ loan.loan_concept }}
                    </li>
                  </ul>
                  <mat-divider  [inset]="true"
                                class="my-3"
                                *ngIf="customer?.loan?.length > 0">
                  </mat-divider>
                  <p class="list-group-item-heading">Cuota: {{ ((customer.debt > 0) ? customer.debt : customer.customer_fee) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                  <p  class="list-group-item-heading"
                      [class.text-danger]="customer.debt > customer.payed"
                      [class.font-weight-bold]="customer.debt > customer.payed">Pagado hoy: {{ customer.payed | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                </div>
                <div class="list-group-item-controls">
                  <button mat-button
                          class="custom-button mx-auto my-2"
                          *ngIf="customer?.payed > 0 && customer?.payed >= customer.customer_fee"
                          disabled><mat-icon class="mr-2">check_circle_outline</mat-icon>Cobrado</button>
                  <button mat-button
                          class="custom-button mx-auto my-2"
                          *ngIf="customer?.payed == 0 || customer?.payed < customer.customer_fee"
                          (click)="openTodayAddDialog(i)">Agregar pago</button>
                  <button mat-button class="custom-button mx-auto"
                          [routerLink]="['/' + typeCollector,'main','add-payment',customer?.customer_id]">Detalles</button>
                </div>
              </li>
            </div>

            <h1 class="mx-auto text-center">Cobros de hoy</h1>
            <div *ngFor="let customer of todayCustomerData, let i = index">
              <li class="list-group-item list-group-item-info">
                <div class="list-group-item-content">

                  <!-- Total deuda, total pagado, restante adeudado -->
                  <span class="float-right text-left font-weight-bold">
                    <p class="text-danger">Deuda total: {{ (customer?.customer_debt) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                    <p class="text-success">Total pagado: {{ (customer?.customer_paid) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                    <p class="text-secondary">Pendiente: {{ (customer?.customer_debt - customer?.customer_paid) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                  </span>
                  <p class="list-group-item-heading">Nombre del cliente: {{ customer.customer_name }} {{ customer.customer_lastname }}</p>
                  <p class="list-group-item-heading">ID del cliente: {{ customer.customer_id}}</p>
                  <p class="list-group-item-heading">Direccion: {{ customer.customer_direction }}</p>
                  <p class="list-group-item-heading">Telefono: {{ customer.customer_phonenumber }}</p>
                  <mat-divider  [inset]="true"
                                class="my-3"
                                *ngIf="customer?.loan?.length > 0">
                  </mat-divider>
                  <h3   class="mx-2"
                        *ngIf="customer?.loan?.length > 0">Conceptos</h3>
                  <ul *ngIf="customer?.loan?.length > 0">
                    <li *ngFor="let loan of customer?.loan">
                      {{ loan.loan_concept }}
                    </li>
                  </ul>
                  <mat-divider  [inset]="true"
                                class="my-3"
                                *ngIf="customer?.loan?.length > 0">
                  </mat-divider>
                  <p class="list-group-item-heading">Cuota: {{ ((customer.debt > 0) ? customer.debt : customer.customer_fee) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                  <p  class="list-group-item-heading"
                      [class.text-danger]="customer.debt > customer.payed"
                      [class.font-weight-bold]="customer.debt > customer.payed">Pagado hoy: {{ customer.payed | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                </div>
                <div class="list-group-item-controls">
                  <button mat-button
                          class="custom-button mx-auto my-2"
                          *ngIf="customer?.payed > 0"
                          disabled><mat-icon class="mr-2">check_circle_outline</mat-icon>Cobrado</button>
                  <button mat-button
                          class="custom-button mx-auto my-2"
                          *ngIf="customer?.payed == 0"
                          (click)="openAddDialog(i)">Agregar pago</button>
                  <button mat-button class="custom-button mx-auto"
                          [routerLink]="['/' + typeCollector,'main','add-payment',customer?.customer_id]">Detalles</button>
                </div>
              </li>
            </div>
            <h1 class="mx-auto text-center my-5"
                *ngIf="pendingLoanData.length > 0">Cobros pendientes</h1>
            <div *ngFor="let customer of pendingLoanData, let j = index">
              <li class="list-group-item list-group-item-info">
                <div class="list-group-item-content">

                  <!-- Total deuda, total pagado, restante adeudado -->
                  <span class="float-right text-left font-weight-bold">
                    <p class="text-danger">Deuda total: {{ (customer?.customer_debt) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                    <p class="text-success">Total pagado: {{ (customer?.customer_paid) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                    <p class="text-secondary">Pendiente: {{ (customer?.customer_debt - customer?.customer_paid) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                  </span>
                  <p class="list-group-item-heading">Nombre del cliente: {{ customer.customer_name }} {{ customer.customer_lastname }}</p>
                  <p class="list-group-item-heading">ID del cliente: {{ customer.customer_id}}</p>
                  <p class="list-group-item-heading">Direccion: {{ customer.customer_direction }}</p>
                  <p class="list-group-item-heading">Telefono: {{ customer.customer_phonenumber }}</p>
                  <mat-divider  [inset]="true"
                                class="my-3"
                                *ngIf="customer?.loan?.length > 0">
                  </mat-divider>
                  <h3   class="mx-2"
                        *ngIf="customer?.loan?.length > 0">Conceptos</h3>
                  <ul *ngIf="customer?.loan?.length > 0">
                    <li *ngFor="let loan of customer?.loan">
                      {{ loan.loan_concept }}
                    </li>
                  </ul>
                  <mat-divider  [inset]="true"
                                class="my-3"
                                *ngIf="customer?.loan?.length > 0">
                  </mat-divider>
                  <p class="list-group-item-heading">Cuota: {{ ((customer.debt > 0) ? customer.debt : customer.customer_fee) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                  <p  class="list-group-item-heading"
                      [class.text-danger]="customer.debt > customer.payed"
                      [class.font-weight-bold]="customer.debt > customer.payed">Pagado hoy: {{ customer.payed | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                </div>
                <div class="list-group-item-controls">
                  <button mat-button
                          class="custom-button mx-auto my-2"
                          *ngIf="customer?.payed > 0"
                          disabled><mat-icon class="mr-2">check_circle_outline</mat-icon>Cobrado</button>
                  <button mat-button
                          class="custom-button mx-auto my-2"
                          *ngIf="customer?.payed == 0"
                          (click)="openAddDialogPendding(j)">Agregar pago</button>
                  <button mat-button class="custom-button mx-auto"
                          [routerLink]="['/' + typeCollector,'main','add-payment',customer?.customer_id]">Detalles</button>
                </div>
              </li>
            </div>
            <h1 class="mx-auto text-center my-5">Proximos cobros</h1>
            <div *ngFor="let customer of nextCustomerData, let i = index">
              <li class="list-group-item list-group-item-info">
                <div class="list-group-item-content">
                  <p class="list-group-item-heading">Nombre del cliente: {{ customer.customer_name }} {{ customer.customer_lastname }}</p>
                  <p class="list-group-item-heading">Día de cobro: {{ customer.customer_payday }} de cada mes</p>
                  <!-- <p class="list-group-item-heading">Total a pagar: {{ customer.cuota | currency : "EUR" : "symbol" }}</p>
                  <p class="list-group-item-heading">Pagado hoy: {{ customer.payed | currency : "EUR" : "symbol" }}</p> -->
                </div>
                <div class="list-group-item-controls">
                  <button mat-button class="custom-button mx-auto"
                          [routerLink]="['/' + typeCollector,'main','add-payment',customer?.customer_id]">Detalles
                  </button>
                </div>
              </li>
            </div>
          </div>


      </div>
    </div>
</div>
