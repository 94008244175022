import { Component, OnInit } from '@angular/core';
import { TYPE_ADMIN, GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit {

  readonly typeAdmin = TYPE_ADMIN ;
  customer_dni : string;
  customer_name : string;
  customer_lastname : string;
  customer_direction : string;
  customer_payday : number;
  customer_email : string;
  customer_phonenumber : string;
  customer_register : Date | string;
  customer_paydate : Date | string;
  customer_id : string;

  constructor(public generalService: GeneralService,private route: ActivatedRoute,private _location: Location) {
    this.route.params.subscribe(parametros => {
      this.customer_id = parametros.id;
      this.loadClient();
    });
  }
  loadClient(){
    this.generalService.getCustomerById(this.customer_id).subscribe( responseCustomer => {
      console.log(responseCustomer);
      if (responseCustomer[1] == "no result") {
        Swal.fire('Error en la carga de datos del cliente','','error');
      } else {
        this.customer_dni = responseCustomer[1][0].customer_dni;
        this.customer_name = responseCustomer[1][0].customer_name;
        this.customer_lastname = responseCustomer[1][0].customer_lastname;
        this.customer_direction = responseCustomer[1][0].customer_direction;
        //this.customer_payday = responseCustomer[1][0].customer_payday;
        this.customer_email = responseCustomer[1][0].customer_email;
        this.customer_phonenumber = responseCustomer[1][0].customer_phonenumber;
        this.customer_register = new Date(responseCustomer[1][0].customer_register);
        this.customer_paydate = new Date(responseCustomer[1][0].customer_paydate);
        this.customer_id = responseCustomer[1][0].customer_id;
      }
    })
  }
  
  updateClient(){
    let register = this.customer_register;
    if (this.customer_register instanceof Date) {
      register = this.generalService.formatDate(this.customer_register);
    }

    let paydate = this.customer_paydate;
    if (this.customer_paydate instanceof Date) {
      paydate = this.generalService.formatDate(this.customer_paydate);
    }
    
    this.customer_payday = new Date(this.customer_paydate).getDate();

    this.generalService.updateCustomer(this.customer_dni,this.customer_name,this.customer_lastname,
      this.customer_email,this.customer_phonenumber,this.customer_direction, this.customer_payday,
      this.customer_id, register as string, paydate as string).subscribe(response => {
      console.log(response);
      if (response[0] == true) {
        Swal.fire('Cliente modificado', '', 'success');
      } else {
        Swal.fire('No se pudo modificar el cliente', '', 'error');
      }
    });
  }
  back(){
   this._location.back();
  }
  ngOnInit(): void {
  }

}
