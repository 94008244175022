import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { GeneralService, TYPE_ADMIN } from 'src/app/services/general.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-register-customer',
  templateUrl: './register-customer.component.html',
  styleUrls: ['./register-customer.component.scss']
})
export class RegisterCustomerComponent implements OnInit {

  customerFormGroup: FormGroup;
  readonly typeAdmin = TYPE_ADMIN ;
  constructor(private generalService : GeneralService,
    private fb : FormBuilder, private router : Router) { }

  ngOnInit(): void {
    this.customerFormGroup = this.fb.group({
      dni: ['',[Validators.pattern('^([0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE|trwagmyfpdxbnjzsqvhlcke]|[XYZ|xyz][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE|trwagmyfpdxbnjzsqvhlcke])$')]],
      id: ['',[Validators.required]],
      guarantee: ['',[]],
      register: [new Date(),[Validators.required]],
      name: ['',[Validators.required]],
      lastname: ['',[Validators.required]],
      address: ['',[Validators.required]],
      email: ['', [Validators.email]],
      payday: [''],
      paydate: [new Date(),[Validators.required]],
      phone: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
      fee: [1, [Validators.pattern('^[0-9]*$')]],
    });
  }

  registerCustomer() {
    /*if (this.customerFormGroup.invalid) {
      this.generalService.showMessage('Campos invalidos', 'Por favor, rellene de forma valida los campos marcados antes de continuar.', 'error');
      return;
    }*/

    const value = this.customerFormGroup.value;
    
    //dia de cobro 
    value.payday = value.paydate.getDate();
    //dia de registro
    value.register = this.generalService.formatDate(value.register);
    //dia de cobro en date
    value.paydate = this.generalService.formatDate(value.paydate);
    
    console.log(value);

    


    this.generalService.registerCustomer(value).subscribe(respuesta => {
      console.log(respuesta);
      if (respuesta[0] == false) {
        if (respuesta[1] == 'el correo electronico que desea colocar ya esta en uso') {
          this.generalService.showMessage('El correo que ha introducido ya esta en uso', '', 'error');
          return;
        }
        this.generalService.showMessage('El cliente no pudo ser creado', '', 'error');
      } else {
        this.generalService.showMessage('Cliente creado', '', 'success');
        console.log(respuesta);
        this.router.navigate(['/' + this.typeAdmin,'main','admin-clients']);
      }
    });
  }

}
