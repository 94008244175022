import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';

import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { AdminClientsComponent } from './components/admin/admin-clients/admin-clients.component';
import { AdminCollectorsComponent } from './components/admin/admin-collectors/admin-collectors.component';
import { AdminLoansComponent } from './components/mod/admin-loans/admin-loans.component';
import { AdminOrdersComponent } from './components/admin/admin-orders/admin-orders.component';
import { MatNativeDateModule } from '@angular/material/core';
import { RegisterCollectorComponent } from './components/admin/register-collector/register-collector.component';
import { RegisterCustomerComponent } from './components/admin/register-customer/register-customer.component';
import { CollectorDetailsComponent } from './components/admin/collector-details/collector-details.component';
import { ClientDetailsComponent } from './components/admin/client-details/client-details.component';
import { EditCollectorComponent } from './components/admin/edit-collector/edit-collector.component';
import { EditClientComponent } from './components/admin/edit-client/edit-client.component';
import { RegisterOrderComponent } from './components/admin/register-order/register-order.component';
import { OrderDetailsComponent } from './components/admin/order-details/order-details.component';
import { AddPaymentComponent } from './components/mod/add-payment/add-payment.component';
import { HomeComponent } from './components/home/home.component';
import { AddPaymentDialogComponent } from './components/dialog/add-payment-dialog/add-payment-dialog.component';
import { AddClientsComponent } from './components/admin/add-clients/add-clients.component';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { StatisticsComponent } from './components/statistics/statistics.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    AdminClientsComponent,
    AdminCollectorsComponent,
    AdminLoansComponent,
    AdminOrdersComponent,
    RegisterCollectorComponent,
    RegisterCustomerComponent,
    CollectorDetailsComponent,
    ClientDetailsComponent,
    EditCollectorComponent,
    EditClientComponent,
    RegisterOrderComponent,
    OrderDetailsComponent,
    AddPaymentComponent,
    HomeComponent,
    AddPaymentDialogComponent,
    AddClientsComponent,
    StatisticsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTableModule,
    MatNativeDateModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatMenuModule
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'es'
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
