<div class="h-100 w-100 bg-black">
  <div class="d-flex justify-content-center align-items-center h-75">
    <mat-card class="col-12 col-md-8 col-xl-4 text-center mat-white">
      <img  class="w-50" src="./assets/jjlogo.jpg">
      <mat-card-header>
        <mat-card-title>Iniciar sesion</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="text-center"
              [formGroup]="credentialsGroup">
          <mat-form-field appearance="fill">
            <mat-label>Tipo de usuario</mat-label>
            <mat-select formControlName="userType">
              <mat-option value="collector">Cobrador</mat-option>
              <mat-option value="admin">Admin</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="d-block">
            <mat-label>
              Correo
            </mat-label>
            <input  formControlName="username"
                    matInput />
          </mat-form-field>
          <mat-form-field class="d-block">
            <mat-label>
              Clave
            </mat-label>
            <input  type="password"
                    matInput
                    formControlName="password" />
          </mat-form-field>
          <button mat-flat-button color="primary" (click)="login()">Iniciar sesion</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
