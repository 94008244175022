<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>Detalles de cobrador</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field class="d-block">
          <mat-label>
            Nombre
          </mat-label>
          <input [(ngModel)]="collector_name" [ngModelOptions]="{standalone: true}" matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Apellido
          </mat-label>
          <input  [(ngModel)]="collector_lastname" [ngModelOptions]="{standalone: true}" matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            DNI
          </mat-label>
          <input  [(ngModel)]="collector_dni" [ngModelOptions]="{standalone: true}"  matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Email
          </mat-label>
          <input disabled [(ngModel)]="collector_email" [ngModelOptions]="{standalone: true}" matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Telefono
          </mat-label>
          <input  [(ngModel)]="collector_phonenumber" [ngModelOptions]="{standalone: true}" matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Clave
          </mat-label>
          <input type="password" [(ngModel)]="collector_password" [ngModelOptions]="{standalone: true}" matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Repetir clave
          </mat-label>
          <input [(ngModel)]="r_password" [ngModelOptions]="{standalone: true}" type="password" matInput>
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="updateCollector()" class="mr-1 mb-1">Modificar cobrador</button>
        <button mat-flat-button color="warn" [routerLink]="['/' + typeAdmin,'main','admin-collectors']">Volver</button>
    </mat-card-content>
  </mat-card>
</div>
