import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { GeneralService, TYPE_ADMIN } from 'src/app/services/general.service';
import { Router } from '@angular/router';
import { Customer } from 'src/app/classes/customer';
@Component({
  selector: 'app-register-order',
  templateUrl: './register-order.component.html',
  styleUrls: ['./register-order.component.scss']
})
export class RegisterOrderComponent implements OnInit {
  customer_dni : string;
  loan_totaldebt : string;
  loan_concept : string;
  loan_date : Date = new Date();
  customersData : Customer[] = [];
  filteredCustomers : Observable<Customer[]>;
  readonly typeAdmin = TYPE_ADMIN;
  customerById : any = {};

  public totaldebtControl : FormControl;
  public customerControl : FormControl;

  constructor(private generalService : GeneralService,
    private router : Router, private formBuilder : FormBuilder) {

    this.totaldebtControl = this.formBuilder.control(0, [Validators.required, Validators.pattern('^[0-9]*$')]);
    this.customerControl = this.formBuilder.control('', [Validators.required, this.validateList(this)]);
    this.loadCustomers();
  }

  loadCustomers() {

    this.generalService.getCustomers().subscribe( responseCustomers =>{
      console.log(responseCustomers);
      if (responseCustomers[1] == 'no result') {
        this.generalService.showMessage('No se han cargado clientes', '', 'info');
      } else {
        this.customersData = responseCustomers[1];
        this.customersData.forEach(customer => {
          this.customerById[customer.customer_id] = `${customer.customer_name} ${customer.customer_lastname}`;
        });

        this.filteredCustomers = this.customerControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    });
  }

  registerLoan() {
    if (this.customerControl.invalid) {
      this.generalService.showMessage('ID de cliente no registrado', '', 'error');
      return;
    }

    if (this.totaldebtControl.invalid) {
      this.generalService.showMessage('El total debe ser numero entero', '', 'error');
      return;
    }

    const customerId = this.customerControl.value;
    this.generalService.registerLoan(this.totaldebtControl.value, this.loan_concept,
      customerId, this.generalService.formatDate(this.loan_date)).subscribe( responseLoan => {
      console.log(responseLoan);
      if (responseLoan[0] == false) {
        this.generalService.showMessage('No se pudo generar la compra', '', 'error');
      } else {
        this.generalService.showMessage('Compra generada', '', 'success');
        this.router.navigate(['/' + this.typeAdmin,'main','admin-orders']);
      }
    });
  }
  ngOnInit(): void {
  }

  private _filter(value : string) : Customer[] {
    const filterValue = value.toLowerCase();

    return this.customersData.filter(item => `${item.customer_id} - ${item.customer_name} ${item.customer_lastname}`
      .toLowerCase().includes(filterValue));
  }

  private validateList(component : RegisterOrderComponent) {
    return (control : FormControl) => {
      const data = component.customersData;
      const validate = data.filter(item => item.customer_id == control.value);

      return (validate.length <= 0) ? { dni: 'true' } : null
    };
  }

}
