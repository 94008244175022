import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { GeneralService, TYPE_ADMIN } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-register-collector',
  templateUrl: './register-collector.component.html',
  styleUrls: ['./register-collector.component.scss']
})
export class RegisterCollectorComponent implements OnInit {
  collector_email : string;
  collector_name : string;
  collector_lastname : string;
  collector_dni : string;
  collector_phonenumber : string;
  collector_password : string;
  collector_r_password : string;
  readonly typeAdmin = TYPE_ADMIN ;

  collectorFormGroup: FormGroup;

  constructor(private generalService : GeneralService,
    private fb : FormBuilder ,private router : Router) { }

  ngOnInit(): void {
    this.collectorFormGroup = this.fb.group({
      email: ['', [Validators.required,Validators.email]],
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dni: ['',[Validators.required, Validators.pattern('^([0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE|trwagmyfpdxbnjzsqvhlcke]|[XYZ|xyz][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE|trwagmyfpdxbnjzsqvhlcke])$')]],
      phonenumber: ['',[Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      repPassword: ['', [Validators.required, Validators.minLength(8)]]
    });
  }


  registerCollector() {
    console.log(this.collectorFormGroup.value);

    if (this.collectorFormGroup.invalid) {
      this.generalService.showMessage('Campos invalidos', 'Por favor, rellene de forma valida los campos marcados antes de continuar.', 'error');
      return;
    }

    const value = this.collectorFormGroup.value;

    if (value.password != value.repPassword) {
      this.generalService.showMessage('Las contraseñas no coinciden', '', 'error');
    } else {
      this.generalService.registerCollector(value).subscribe(respuesta => {
        if (respuesta[0] == false) {
          console.log(respuesta);
          if (respuesta[1] == 'el correo electronico que desea colocar ya esta en uso') {
            this.generalService.showMessage('El correo que ha introducido ya esta en uso', '', 'error');
            return;
          }
          this.generalService.showMessage('El cobrador no pudo ser creado', '', 'error');
        } else {
          this.generalService.showMessage('Cobrador creado', '', 'success');
          console.log(respuesta);
          this.router.navigate(['/' + this.typeAdmin,'main','admin-collectors']);
        }
      });
    }
  }

}
