<h1 mat-dialog-title>
  Registrar pago para {{ data.customer_name }} {{ data.customer_lastname }}
</h1>
<mat-dialog-content>
  <div  class="alert alert-info"
        role="alert"
        *ngIf="data.customer_message.length > 0">
    <h2 class="alert-heading font-weight-bold">La cuota ha sido cambiada</h2>
    <p><span class="font-weight-bold">Motivo:</span> {{ data.customer_message }}</p>
  </div>
  <h2 *ngIf="data?.loan.length > 0">Conceptos de compras</h2>
  <ul>
    <li  *ngFor="let loan of data?.loan"
          class="lead m-5">
      {{ loan.loan_concept }}
    </li>
  </ul>
  <form [formGroup]="paymentGroup"
        (ngSubmit)="send()"
        class="px-3">
    <mat-form-field>
      <mat-label>
        Cantidad pagada
      </mat-label>
      <input  matInput
              formControlName="installments_payment">
      <mat-error *ngIf="paymentGroup.get('installments_payment').hasError('required')">Este campo es requerido</mat-error>
      <mat-error *ngIf="paymentGroup.get('installments_payment').hasError('pattern')">Valor invalido</mat-error>
      <mat-error *ngIf="paymentGroup.get('installments_payment').hasError('debtSurpass')">El valor excede la deuda</mat-error>
    </mat-form-field> € / {{ paymentValue | currency : 'EUR' : 'symbol' : '1.0-0' }}
    <div class="mt-2">
      <button mat-button
              class="mx-2"
              type="button"
              (click)="close()"
              color="warn"><mat-icon>close</mat-icon>Cerrar</button>
      <button mat-button
              class="mx-2">Registrar pago</button>
    </div>
  </form>
</mat-dialog-content>
