<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>
        Detalles de cliente
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="d-block">
        <mat-label>
          Fecha de registro
        </mat-label>
        <input disabled  [ngModel]="customer_register | date" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Nombre
        </mat-label>
        <input disabled  [(ngModel)]="customer_name" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Apellido
        </mat-label>
        <input disabled [(ngModel)]="customer_lastname" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          DNI
        </mat-label>
        <input disabled [(ngModel)]="customer_dni" [ngModelOptions]="{standalone: true}"  matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          ID
        </mat-label>
        <input disabled [(ngModel)]="customer_id" [ngModelOptions]="{standalone: true}"  matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Dirección
        </mat-label>
        <input disabled [(ngModel)]="customer_direction" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Email
        </mat-label>
        <input disabled [(ngModel)]="customer_email" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Telefono
        </mat-label>
        <input disabled [(ngModel)]="customer_phonenumber" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Cuota
        </mat-label>
        <input  disabled
                [ngModel]="customer_fee | currency : 'EUR' : 'symbol' : '1.0-0'"
                [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-divider  [inset]="true"
                    class="my-3">
      </mat-divider>
      <span class="mx-2 d-block text-danger lead">Deuda: {{ customer_debt | currency : 'EUR' : 'symbol' : '1.0-0' }}</span>
      <span class="mx-2 d-block text-warning lead">Pendiente: {{ customer_debt - customer_paid | currency : 'EUR' : 'symbol' : '1.0-0' }}</span>
      <span class="mx-2 d-block text-success lead">Pagado: {{ customer_paid | currency : 'EUR' : 'symbol' : '1.0-0' }}</span>
      <button mat-flat-button
              class="my-3"
              color="primary"
              (click)="goToPDF()">
        <mat-icon>description</mat-icon>
        Generar resumen PDF
      </button>
      <mat-divider  [inset]="true"
                    class="my-3">
      </mat-divider>
      <button mat-flat-button
              color="primary"
              class="mx-2 mt-2"
              (click)="showFeeForm = true">Cambiar cuota</button>
      <button mat-flat-button
              color="accent"
              [routerLink]="['/admin', 'main', 'add-payment', customer_id]"
              class="mx-2 mt-2">Ver pagos</button>
      <button mat-flat-button
              color="warn"
              class="mx-2 mt-2"
              (click)="back()">Volver</button>
    </mat-card-content>
  </mat-card>
</div>

<div  class="d-flex justify-content-center align-items-center"
      *ngIf="showFeeForm">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center mt-5">
    <mat-card-header>
      <mat-card-title>Cambiar cuota</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="feeGroup"
            (ngSubmit)="updateFee()">
        <mat-form-field class="d-block">
          <mat-label>
            Cuota (en Euros)
          </mat-label>
          <input matInput formControlName="fee">
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Mensaje
          </mat-label>
          <textarea rows="3"
                    formControlName="message"
                    matInput></textarea>
        </mat-form-field>
        <button mat-flat-button
                color="primary"
                class="mx-2">Guardar</button>
        <button mat-flat-button
                color="warn"
                class="mx-2"
                (click)="showFeeForm = false">Cancelar</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<h1 class="text-center mt-5">Compras del cliente</h1>
<h2 *ngIf="loanData?.length <= 0"
    class="text-center">No hay compras registradas</h2>
<div class="container">
    <div id="fancy-list-group">
      <div class="row">
          <div class="col-lg-12">
            <li class="list-group-item list-group-item-info" *ngFor="let loan of loanData">
              <div class="list-group-item-content">
                <h4 class="list-group-item-heading">Fecha de compra: {{ loan.loan_date | date }}</h4>
                <p class="list-group-item-heading">Concepto: {{ loan.loan_concept }}</p>
                <p class="list-group-item-heading">Nombre del cliente: {{ loan.customer_name }}</p>
                <p class="list-group-item-heading">Total de la compra: {{ loan.loan_totaldebt | currency : 'EUR' : 'symbol' : '1.0-0' }}</p>
              </div>
              <div class="list-group-item-controls">
                <span class="label mt-2">Acciones</span>
                <button mat-button class="custom-button-clients mb-2" [routerLink]="['/' + typeAdmin,'main','order-detail',loan.loan_id]"><mat-icon>add</mat-icon></button>
              </div>
            </li>

          </div>
      </div>
    </div>
</div>
