import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { GeneralService } from './../../services/general.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private _credentialsGroup : FormGroup;

  constructor(private formBuilder : FormBuilder,
    private generalService : GeneralService, private router : Router) {

    this.credentialsGroup = this.formBuilder.group({
      userType: [''],
      username: [''],
      password: ['']
    });
  }

  login() : void {
    const value = this.credentialsGroup.value;
    console.log(value);

    this.generalService.login(value).subscribe((response) => {
      console.log(response);

      if (!response[0]) {
         console.error("error", response);
      } else {

        if (response[1] == "usuario invalido" || response[1] == "password invalida"
          || response[1] == "usuario desabilitado") {
          this.generalService.showMessage('Error al iniciar sesion', 'Credenciales invalidas.', 'error');
        } else {
          console.log("Success token");

          sessionStorage.setItem('session_token', response[1].toString());
          this.generalService.setUserType(value.userType);
          const route = (value.userType == 'collector') ? 'home' : 'statistics';

          this.router.navigate(['/' + value.userType, 'main', route]);
        }
      }
    });
  }

  public get credentialsGroup() : FormGroup {
    return this._credentialsGroup;
  }

  public set credentialsGroup(value : FormGroup) {
    this._credentialsGroup = value;
  }

  public ngOnInit(): void {
  }

  /*public login() : void {
    this.generalService.login(this.credentialsGroup.value);
  }*/
}
