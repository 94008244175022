<mat-card class="mx-auto col-12 col-md-10 col-lg-8">
  <mat-card-header>
    <mat-card-title>Estadisticas</mat-card-title>
    <mat-card-subtitle>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="text-center my-5">
      <!-- <button mat-flat-button
                class="mx-2"
                color="primary"
                (click)="loadToday()">Hoy</button> -->
      <button mat-flat-button
              class="mx-2"
              color="primary"
              (click)="loadWeek()">Semana</button>
      <button mat-flat-button
              class="mx-2"
              color="primary"
              (click)="loadMonth()">Mes</button>
      <mat-form-field class="mx-2">
        <mat-label>Fecha</mat-label>
        <input  matInput
                [matDatepicker]="picker"
                [(ngModel)]="day"
                (dateChange)="loadToday($event.value)" />
        <mat-datepicker-toggle  matSuffix
                                [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-divider  [inset]="true"
                  class="my-5"></mat-divider>
    <p class="lead">Dinero pagado: {{ paid >= 0 ? (paid | currency : "EUR" : "symbol" : '1.0-0') : "Cargando..." }}</p>
    <p class="lead">Dinero esperado: {{ expected >= 0 ? (expected | currency : "EUR" : "symbol" : '1.0-0') : "Cargando..." }}</p>
  </mat-card-content>
</mat-card>
