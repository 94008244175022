import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-payment-dialog',
  templateUrl: './add-payment-dialog.component.html',
  styleUrls: ['./add-payment-dialog.component.scss']
})
export class AddPaymentDialogComponent {

  public paymentValue : number;
  private _paymentGroup : FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any, private formBuilder : FormBuilder) {

    console.log(data);

    this.paymentGroup = this.formBuilder.group({
      installments_payment: ['', [Validators.required, Validators.pattern('^[0-9]*$'), this.validateValue(data)]]
    });

    this.paymentValue = parseFloat(data.customer_debt) - parseFloat(data.customer_paid);
    this.paymentValue = (this.paymentValue < parseFloat(data.customer_fee)) ? this.paymentValue : parseFloat(data.customer_fee);
  }

  public get paymentGroup() : FormGroup {
    return this._paymentGroup;
  }

  public set paymentGroup(value : FormGroup) {
    this._paymentGroup = value;
  }

  private validateValue(data) : any {

    return (control) => {
      if (parseFloat(control.value) > parseFloat(data.customer_debt) - parseFloat(data.customer_paid)) {

        return {
          debtSurpass: true
        };
      }

      return null;
    };
  }

  public send() : void {
    console.log(this.paymentGroup);

    if (this.paymentGroup.invalid == true) {
      return;
    }

    const value = this.paymentGroup.get('installments_payment').value;

    this.dialogRef.close(value);
  }

  public close() : void {
    this.dialogRef.close();
  }
}
