import { Component, OnInit } from '@angular/core';
import { GeneralService,TYPE_ADMIN, TYPE_COLLECTOR } from 'src/app/services/general.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Installment } from 'src/app/classes/installment';
import { Location, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  readonly typeAdmin = TYPE_ADMIN ;
  readonly typeCollector = TYPE_COLLECTOR ;
  installments_payment : any;
  installments : Installment[] = [];
  loan_id : number;
  loan_date : Date;
  loan_totaldebt : number;
  loan_payday : number;
  loan_concept : string;
  loan_status : number;

  editMode = false;

  constructor(private generalService: GeneralService, private route: ActivatedRoute,private _location: Location) {
    this.route.params.subscribe(parametros => {
      this.loan_id = parametros.id;
      this.loadLoan();
    });
  }
  loadLoan() {
    this.generalService.getLoanById(this.loan_id.toString()).subscribe( responseLoan => {
      console.log(responseLoan);
      if (responseLoan[1] == "no result") {
        Swal.fire('Error en la carga de datos de la compra','','error');
      } else {
        this.loan_id = responseLoan[1][0].loan_id;
        this.loan_date = new Date(responseLoan[1][0].loan_date);
        this.loan_totaldebt = responseLoan[1][0].loan_totaldebt;
        this.loan_concept = responseLoan[1][0].loan_concept;
        this.loan_status = responseLoan[1][0].loan_status;
      }
    })
  }

  editOrder() : void {
    let date;
    if (this.loan_date instanceof Date) {
      date = this.generalService.formatDate(this.loan_date);
    }

    this.generalService.updateLoan(this.loan_id, this.loan_totaldebt, date, this.loan_concept)
      .subscribe(response => {
      console.log(response);

      this.loadLoan();
      this.editMode = false;

      if (response == null) {
        return;
      }

      if (response[0] == false) {
        this.generalService.showMessage('Error al modificar compra', '', 'error');
        return;
      }
    });
  }

  back() {
   this._location.back();
  }

  setLoanStatus() : void {
    const status = this.loan_status == 1 ? "0" : "1";
    this.generalService.setLoanStatus(this.loan_id.toString(), status).subscribe(response => {
      if (response[0] == false) {
        this.generalService.showMessage('Error al cambiar estado de compra', '', 'error');
        return;
      }

      this.loadLoan();
      this.generalService.showMessage('El cambio se ha hecho satisfactoriamente', '', 'success');
    })
  }

  ngOnInit(): void {
  }

}
