<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>Registro de cobrador</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="collectorFormGroup"
            (ngSubmit)="registerCollector()">
        <mat-form-field class="d-block">
          <mat-label>
            Nombre
          </mat-label>
          <input  formControlName="name"
                  matInput />
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Apellido
          </mat-label>
          <input  formControlName="lastName"
                  matInput />
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Email
          </mat-label>
          <input  formControlName="email"
                  matInput />
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            DNI
          </mat-label>
          <input  formControlName="dni"
                  matInput />
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Telefono
          </mat-label>
          <input  formControlName="phonenumber"
                  matInput />
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Clave
          </mat-label>
          <input  formControlName="password"
                  type="password"
                  matInput />
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Repetir clave
          </mat-label>
          <input  formControlName="repPassword"
                  type="password"
                  matInput />
        </mat-form-field>
        <button mat-flat-button
                color="primary">Registrar cobrador
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
