import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { GeneralService,TYPE_ADMIN } from 'src/app/services/general.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Customer } from 'src/app/classes/customer';

@Component({
  selector: 'app-collector-details',
  templateUrl: './collector-details.component.html',
  styleUrls: ['./collector-details.component.scss']
})
export class CollectorDetailsComponent implements OnInit {
  readonly typeAdmin = TYPE_ADMIN ;
  collector_dni : string;
  collector_name : string;
  collector_lastname : string;
  collector_email : string;
  collector_phonenumber : string;
  customersData : Customer[] = [];
  allCustomersData : Customer[] = [];
  filteredCustomers : Observable<Customer[]>;
  customerControl : FormControl;
  showAddClient : boolean = false;
  customerById : any = {};

  constructor(public generalService: GeneralService,private route: ActivatedRoute,
    private formBuilder : FormBuilder) {

    this.customerControl = this.formBuilder.control('', this.validateList(this));

    this.route.params.subscribe(parametros => {
      this.loadCollector(parametros.id);
      this.collector_dni = parametros.id;
      this.loadCustomersCollector();
      this.loadAllCustomers();
    });
  }

  private loadCollector(collector_dni : string) : void {
    this.generalService.getCollector(collector_dni).subscribe( responseCollector => {
      console.log(responseCollector);
      if (responseCollector[1] == "no result") {
        Swal.fire('Error en la carga de datos del cobrador','','error');
      } else {
        this.collector_name = responseCollector[1][0].debtcollector_name;
        this.collector_lastname = responseCollector[1][0].debtcollector_lastname;
        this.collector_email = responseCollector[1][0].debtcollector_email;
        this.collector_phonenumber = responseCollector[1][0].debtcollector_phonenumber;
      }
    })
  }

  private loadCustomersCollector() : void {
    this.generalService.getCustomerCollector(this.collector_dni).subscribe( responseCustomers => {
      console.log(responseCustomers);

      if (responseCustomers[0] == false) {
        this.generalService.showMessage('Error inesperado', '', 'error');
      } else {
        if (responseCustomers[1] == 'no result') {
          this.customersData = [];
          return;
        }
        this.customersData = responseCustomers[1];
      }
    });
  }

  private loadAllCustomers() : void {
    this.generalService.getCustomers().subscribe( responseCustomers =>{
      console.log(responseCustomers);
      if (responseCustomers[0] == false) {
        this.generalService.showMessage('Error inesperado', '', 'error');
      } else {
        if (responseCustomers[1] == 'no result') {
          this.allCustomersData = [];
          return;
        }
        this.allCustomersData = responseCustomers[1];
        this.allCustomersData.forEach(customer => {
          this.customerById[customer.customer_id] = `${customer.customer_name} ${customer.customer_lastname}`;
        });

        this.filteredCustomers = this.customerControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    });
  }

  public insertRelation() : void {
    console.log(this.customerControl.value);

    if (this.customerControl.invalid) {
      this.generalService.showMessage('Debe seleccionar un cliente valido', '', 'error');
      return;
    }

    this.generalService.insertRelation(this.customerControl.value, this.collector_dni)
      .subscribe(response => {
      console.log(response);
      if (response[0] == false) {
        this.generalService.showMessage('No se pudo añadir el cliente al cobrador seleccionado', '', 'error');
      } else {
        this.generalService.showMessage('Cliente agregado', '', 'success');
        this.loadCustomersCollector();
      }
    });
  }

  public deleteRelation(customer_id : string) : void {
    this.generalService.deleteRelation(customer_id, this.collector_dni).subscribe(response => {
      console.log(response);

      if (response[0] == false) {
        this.generalService.showMessage('No se pudo eliminar el cliente seleccionado del cobrador', '', 'error');
      } else {
        this.generalService.showMessage('Cliente eliminado del cobrador', '', 'success');
        this.loadCustomersCollector();
      }
    });
  }

  public showClientButton() : void {
    if(this.showAddClient == false){
      this.showAddClient = true;
    }else{
      this.showAddClient = false;
    }
  }

  public ngOnInit() : void {
  }

  private _filter(value : string) : Customer[] {
    const filterValue = value.toLowerCase();

    return this.allCustomersData.filter(item => `${item.customer_id} - ${item.customer_name} ${item.customer_lastname}`
      .toLowerCase().includes(filterValue));
  }

  private validateList(component : CollectorDetailsComponent) {
    return (control : FormControl) => {
      const data = component.allCustomersData;
      const validate = data.filter(item => item.customer_id == control.value);

      return (validate.length <= 0) ? { dni: 'true' } : null
    };
  }

}
