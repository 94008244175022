import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddPaymentDialogComponent } from './../dialog/add-payment-dialog/add-payment-dialog.component';

import { GeneralService, TYPE_COLLECTOR } from 'src/app/services/general.service';
import { Loan } from 'src/app/classes/loan';
import { Customer } from 'src/app/classes/customer';
import { Installment } from 'src/app/classes/installment';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  readonly typeCollector = TYPE_COLLECTOR;

  private serverDate : Date;
  public serverDatePublic : Date;

  public todayCustomerData : Customer[] = [];
  public nextCustomerData : Customer[] = [];
  public actualPayCustomerData : Customer[] = [];

  public todayLoanData : any = {};
  public nextLoanData : any = {};
  public pendingLoanData : any = {};
  public date_complete : string;

  constructor(private generalService : GeneralService, private dialog : MatDialog,private _location: Location) {

  }

  private loadCustomers() : void {

    this.generalService.getCustomerCollector(this.generalService.getUserId()).subscribe(response => {
      console.log("inicio customers");
      console.log(response);
      console.log("fin customers");

      if (response[0] == false) {
        Swal.fire('No hay clientes asignados', '', 'info');
        return;
      }

      response[1] = response[1].map(item => ({
        ...item,
        customer_last_installment: item.customer_last_installment != null ? new Date(`${item.customer_last_installment} 00:00:00`) : null,
        customer_paydate: item.customer_paydate != null ? new Date(`${item.customer_paydate} 00:00:00`) : null,
        debt: 0,
        payed: 0
      }));

      response[1].sort(function (a: { customer_payday: number; }, b: { customer_payday: number; }) {
        if (a.customer_payday > b.customer_payday) {
          return 1;
        }
        if (a.customer_payday < b.customer_payday) {
          return -1;
        }
        return 0;
      });
      //pagos realizados hoy
      this.actualPayCustomerData = response[1]
        .filter(item => {
        return new Date(item.customer_last_installment).getDate() == this.serverDate.getDate() && 
        new Date(item.customer_last_installment).getMonth() == this.serverDate.getMonth() &&
        new Date(item.customer_last_installment).getFullYear() == this.serverDate.getFullYear();
      });
      response[1] = response[1]
        .filter(item => parseFloat(item.customer_debt) > parseFloat(item.customer_paid));

      //console.log(response[1]);
      this.todayCustomerData = response[1]
        .filter(item => {
        // const prevDate = new Date(this.serverDate.getFullYear(),
        //   this.serverDate.getMonth() - 1,
        //   item.customer_payday);

        // const paydayDate = new Date(this.serverDate.getFullYear(),
        //   this.serverDate.getMonth(),
        //   item.customer_payday);
        // console.log(prevDate, paydayDate);

        return item.customer_paydate.getTime() == this.serverDate.getTime();
      });

      
      for (let i = 0; i < this.actualPayCustomerData.length; i++) {

        this.actualPayCustomerData[i].customer_fee = parseInt(this.actualPayCustomerData[i].customer_fee);
        //eliminar duplicados
        for (let j = 0; j < this.todayCustomerData.length; j++) {
          if (this.actualPayCustomerData[i].customer_id == this.todayCustomerData[j].customer_id) {
            this.actualPayCustomerData.splice(i,1);
          }
          
        }
      }
      console.log("actualpay");
      console.log(this.actualPayCustomerData);

      response[1] = response[1].filter(item => +item.customer_debt > +item.customer_paid);
      console.log("pagos de hoy por cobrar");
      console.log(this.todayCustomerData);

      let nextDate : Date = new Date(this.serverDate);
      nextDate.setDate(nextDate.getDate() + 7);
      this.nextCustomerData = response[1]
        .filter(item => {
          // const paydayDate = new Date(this.serverDate.getFullYear(),
          //   this.serverDate.getMonth(),
          //   item.customer_payday);
          // console.log(paydayDate);

          return !this.todayCustomerData.includes(item) && 
            ((item.customer_paydate > this.serverDate && item.customer_paydate <= nextDate) 
              // || (paydayDate > this.serverDate && paydayDate <= nextDate)
              );
      });
      //console.log("pagos pendientes");
      //console.log(this.nextCustomerData);


      this.pendingLoanData = response[1]
        .filter(item => {

        const prevDate = new Date(this.serverDate.getFullYear(),
          this.serverDate.getMonth() - 1,
          item.customer_payday);

        const paydayDate = new Date(this.serverDate.getFullYear(),
          this.serverDate.getMonth(),
          item.customer_payday);
        //console.log(prevDate, paydayDate);

        return !this.todayCustomerData.includes(item) &&
          this.serverDate > item.customer_paydate;
      });
      //console.log(this.pendingLoanData);

      this.loadLoans();
    });
  }


  private loadLoans() : void {
    for (const customer of this.todayCustomerData) {
      this.loadCustomerInstallments(customer);
      this.loadCustomerLoans(customer);
    }

    for (const customer of this.pendingLoanData) {
      this.loadCustomerInstallments(customer);
      this.loadCustomerLoans(customer);
    }
    //datos de cobros de hoy
    for (const customer of this.actualPayCustomerData) {
      this.loadCustomerLoans(customer);
      this.loadCustomerInstallmentsToday(customer);
    }
    //console.log("hoy  con pagos")
    //console.log(this.actualPayCustomerData);
  }

  private loadCustomerLoans(customer : Customer) : void {
    this.generalService.getCustomerActiveLoan(customer.customer_id).subscribe(response => {
      //console.log(response);

      if (response[0] == false) {
        Swal.fire('Error inesperado', '', 'error');
        return;
      } else if (response[1] == "no result") {
        console.log("no hay resultados para", customer);
        return;
      }

      customer.loan = response[1];
    });
  }

  private getDaysInMonth(month : number, year : number) : number {
   return new Date(year, month, 0).getDate();
  }

  private loadCustomerInstallments(customer : Customer) : void {
    //console.log(customer);
    
    this.generalService.getInstallments(customer.customer_id).subscribe(response => {
      //console.log(response);

      customer.payed = 0;

      if (response[0] == false) {
        Swal.fire('Error inesperado', '', 'error');
        return;
      } else if (response[1] == "no result") {
        //console.log("no hay resultados para", customer);
        return;
      }

      response[1]
        .filter(item => {
        //no daba la fecha actual
        //const installmentDate = new Date(item.installments_date);
        //con fecha actual
        const installmentDate = new Date(`${item.installments_date} 00:00:00`);

        return this.serverDate.getFullYear() == installmentDate.getFullYear() &&
          this.serverDate.getMonth() == installmentDate.getMonth() &&
          installmentDate.getDate() >= customer.customer_payday;
      }).forEach(item => {
        customer.payed += +item.installments_payment;
      });

      // if (customer.payed > 0) {
      //   console.log(customer);
      //
      //   const pendingIndex = this.pendingLoanData.indexOf(customer);
      //   if (pendingIndex != -1) {
      //     this.pendingLoanData.splice(pendingIndex, 1);
      //   }
      // }
    });
  }
  //cargar pagos de personas realizados hoy
  loadCustomerInstallmentsToday(customer : Customer) : void {
    
    this.generalService.getInstallments(customer.customer_id).subscribe(response => {
      //console.log("pagos")
      //console.log(response);

      customer.todayPaymentTotal = response[1];

      if (response[0] == false) {
        Swal.fire('Error inesperado', '', 'error');
        return;
      } else if (response[1] == "no result") {
        //console.log("no hay resultados para", customer);
        return;
      }

      response[1]
        .filter(item => {

        const installmentDate = new Date(`${item.installments_date} 00:00:00`);

        return this.serverDate.getFullYear() == installmentDate.getFullYear() &&
          this.serverDate.getMonth() == installmentDate.getMonth() &&
          installmentDate.getDate() == this.serverDate.getDate();
      }).forEach(item => {
        customer.payed += + parseInt(item.installments_payment);
      });

    });
  }
  //pagar hoy
  public openAddDialog(customerIndex : number) : void {
    const customer = this.todayCustomerData[customerIndex];
    const dialogRef = this.dialog.open(AddPaymentDialogComponent, {
      disableClose: true,
      width: '100%',
      data: customer
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addShare(result, customer.customer_id, customer.customer_fee, customer.customer_paydate, 1, customer.customer_payday);
      }
    });
  }
  //pagar pendientes
  public openAddDialogPendding(customerIndex : number) : void {
    const customer = this.pendingLoanData[customerIndex];
    const dialogRef = this.dialog.open(AddPaymentDialogComponent, {
      disableClose: true,
      width: '100%',
      data: customer
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addShare(result, customer.customer_id, customer.customer_fee, customer.customer_paydate, 1, customer.customer_payday);
      }
    });
  }
  //pagar hoy segunda vez
  public openTodayAddDialog(customerIndex : number) : void {
    const customer = this.actualPayCustomerData[customerIndex];
    const dialogRef = this.dialog.open(AddPaymentDialogComponent, {
      disableClose: true,
      width: '100%',
      data: customer
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addShare(result, customer.customer_id, customer.customer_fee, customer.customer_paydate,0);
      }
    });
  }

  private addShare(total : number, customer_id : string, customer_fee : number, customer_paydate? : Date, updatePaydate? : number, customer_payday?){
    this.generalService.registerInstallment(this.date_complete, total, customer_id, customer_fee)
      .subscribe(response => {
      console.log(response);
      if (response[0] == true) {

        //actualizacion de paydate casos borde
        //next paydate
        var payDateCustomer = new Date(`${customer_paydate.getFullYear()}-${customer_paydate.getMonth()+2}-${customer_paydate.getDate()} 00:00:00`);
        console.log("dia de pago");
        console.log(payDateCustomer);
      
        if (updatePaydate == 1) {
          //validar caso borde 
          if (parseInt(customer_payday) != customer_paydate.getDate()) {
            console.log("caso borde");
            var payDateCustomerDistintc = new Date(`${customer_paydate.getFullYear()}-${customer_paydate.getMonth()+1}-${parseInt(customer_payday)} 00:00:00`);
            this.generalService.updateCustomerPaydate(customer_id,this.generalService.formatDate(payDateCustomerDistintc)).subscribe(response => {
              console.log(response);
              Swal.fire({
                title: 'Cuota pagada',
                text:'',
                icon: 'success',
                allowEscapeKey : false,
                allowOutsideClick: false,
                heightAuto: false
              }).then((result) =>{
                window.location.reload();
              });
            });
          }else{
            //validar recuento de mes
            //validar inicio de mes
            if ((payDateCustomer.getMonth() - customer_paydate.getMonth()) == 2) {
              console.log("pago dia abil");
              //validar dia de pago
              //var lastDay = new Date (this.serverDate.getFullYear(),this.serverDate.getMonth() + 1,0);
              
              var payDateCustomerBd = new Date(`${customer_paydate.getFullYear()}-${customer_paydate.getMonth()+3}-${1} 00:00:00`);
              console.log(payDateCustomerBd)
              this.generalService.updateCustomerPaydate(customer_id,this.generalService.formatDate(payDateCustomerBd)).subscribe(response => {
                console.log(response);
                Swal.fire({
                  title: 'Cuota pagada',
                  text:'',
                  icon: 'success',
                  allowEscapeKey : false,
                  allowOutsideClick: false,
                  heightAuto: false
                }).then((result) =>{
                  window.location.reload();
                });
              });
            }else{
              //var lastDay = new Date (this.serverDate.getFullYear(),this.serverDate.getMonth() + 1,0);
              //console.log(lastDay);
              
              //validar dia de pago
              this.generalService.updateCustomerPaydate(customer_id,this.generalService.formatDate(payDateCustomer)).subscribe(response => {
                console.log(response);
                Swal.fire({
                  title: 'Cuota pagada',
                  text:'',
                  icon: 'success',
                  allowEscapeKey : false,
                  allowOutsideClick: false,
                  heightAuto: false
                }).then((result) =>{
                  window.location.reload();
                });
              });
            }
          }
        }else{
          console.log("0")
          //reload de data
          Swal.fire({
            title: 'Cuota pagada',
            text:'',
            icon: 'success',
            allowEscapeKey : false,
            allowOutsideClick: false,
            heightAuto: false
          }).then((result) =>{
            window.location.reload();
          });
        }
        
      } else {
        this.generalService.showMessage('No se pudo registrar el pago de la cuota', '', 'error');
      }
    });
    

  }

  ngOnInit() : void {
    this.generalService.getDate().subscribe(response => {
      console.log(response);
      this.date_complete = response;

      this.serverDate = new Date(`${response} 00:00:00`);
      //this.serverDate = new Date(`2021-10-04 00:00:00`);
      this.serverDatePublic = this.serverDate;
      console.log(this.serverDate);


      this.loadCustomers();
    });

  }

}
