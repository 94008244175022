<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>Detalles de compra</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field class="d-block">
          <mat-label>
            Id de compra
          </mat-label>
          <input disabled  [(ngModel)]="loan_id" [ngModelOptions]="{standalone: true}" matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>Fecha de compra</mat-label>
          <input  matInput
                  [matDatepicker]="picker"
                  [(ngModel)]="loan_date"
                  [ngModelOptions]="{standalone: true}"
                  [disabled]="!editMode">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Total de compra
          </mat-label>
          <input  [disabled]="!editMode"
                  [(ngModel)]="loan_totaldebt"
                  [ngModelOptions]="{standalone: true}"
                  matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Concepto
          </mat-label>
          <input  [disabled]="!editMode"
                  [(ngModel)]="loan_concept"
                  [ngModelOptions]="{standalone: true}"
                  matInput />
        </mat-form-field>
        <button class="m-2"
                mat-flat-button
                color="primary"
                (click)="editMode = true"
                *ngIf="!editMode">Editar</button>
        <button class="m-2"
                mat-flat-button
                color="warn"
                (click)="back()"
                *ngIf="!editMode">Volver</button>
        <button mat-button
                *ngIf="editMode == false"
                color="primary"
                (click)="setLoanStatus()">{{ loan_status == "1" ? 'Desactivar' : 'Activar' }}</button>
        <button class="m-2"
                mat-flat-button
                color="primary"
                *ngIf="editMode"
                (click)="editOrder()">Guardar</button>
        <button class="m-2"
                mat-flat-button
                color="warn"
                (click)="editMode = false"
                *ngIf="editMode">Cancelar</button>
    </mat-card-content>
  </mat-card>
</div>
