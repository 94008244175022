import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/classes/customer';
import { GeneralService } from 'src/app/services/general.service';
import { Collector } from 'src/app/classes/collector';

@Component({
  selector: 'app-add-clients',
  templateUrl: './add-clients.component.html',
  styleUrls: ['./add-clients.component.scss']
})
export class AddClientsComponent implements OnInit {
  customersData : Customer[] = [];
  collectorsData : Collector[] = [];
  customer_dni : string;
  debtcollector_dni : string;
  constructor(private generalService : GeneralService) {
     this.loadCustomers();
     this.loadCollectors();

  }
  loadCustomers(){
    this.generalService.getCustomers().subscribe( responseCustomers =>{
      console.log(responseCustomers);
      if(responseCustomers[1] == 'no result'){
        this.generalService.showMessage('No se han cargado clientes', '', 'info');
      }else{
        this.customersData = responseCustomers[1];
      }
    });
  }

  loadCollectors(){
    this.generalService.getCollectors().subscribe( responseCollectors =>{
      console.log(responseCollectors);
      if(responseCollectors[1] == 'no result'){
        this.generalService.showMessage('No se han cargado cobradores', '', 'info');
      }else{
        this.collectorsData = responseCollectors[1];
      }
    });
  }
  inserRelation(){
    if(this.customer_dni == undefined){
      this.generalService.showMessage('Debe seleccionar un cliente', '', 'error');
    }else if(this.debtcollector_dni == undefined){
      this.generalService.showMessage('Debe seleccionar un cobrador', '', 'error');
    }else{
      this.generalService.insertRelation(this.customer_dni, this.debtcollector_dni).subscribe(responseInsert => {
        if (responseInsert[0] == false) {
          this.generalService.showMessage('No se pudo añadir el cliente al cobrador seleccionado', '', 'error');
        } else {
          this.generalService.showMessage('Cliente agregado', '', 'success');
          console.log(responseInsert);
        }
      });
    }

  }

  ngOnInit(): void {
  }

}
