import { Component, OnInit } from '@angular/core';
import { GeneralService, TYPE_ADMIN } from 'src/app/services/general.service';

import { Loan } from 'src/app/classes/loan';
import { Customer } from 'src/app/classes/customer';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.scss']
})
export class AdminOrdersComponent implements OnInit {
  readonly typeAdmin = TYPE_ADMIN ;
  loanData : Loan[] = [];
  filteredData : Loan[] = [];

  constructor(private generalService : GeneralService) {
    this.loadLoans();
  }

  loadLoans() : void {
    this.generalService.getLoans().subscribe( responseLoans => {
      console.log(responseLoans);
      if (responseLoans[1] == "no result") {
        Swal.fire('No se han cargado cobros actualmente','','info');
      } else {
        this.loanData = responseLoans[1];
        this.filteredData = this.loanData.filter(item => true);
        this.loadCustomersName();
      }
    });
  }

  loadCustomersName() : void {
    for (let i in this.loanData) {
      this.generalService.getCustomer(this.loanData[i].customer_id).subscribe(responseCustomer => {
        if (responseCustomer[1][0] != null) {
          this.loanData[i].customer_name = `${responseCustomer[1][0].customer_name} ${responseCustomer[1][0].customer_lastname}`;
        }
      });
    }
  }

  ngOnInit() : void {
  }

  applyFilter(filter) : void {
    if (filter.length <= 0) {
      this.filteredData = this.loanData.filter(item => true);
      return;
    }
    this.filteredData = this.loanData.filter(item => {
      const json = JSON.stringify(item).toLowerCase();
      return json.includes(filter.toLowerCase());
    });
  }

}
