<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>Añadir cliente</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field appearance="fill" class="d-block">
        <mat-label>Seleccione un cobrador</mat-label>
        <mat-select [(ngModel)]="debtcollector_dni" [ngModelOptions]="{standalone: true}">
          <mat-option [value]="collector.debtcollector_dni" *ngFor="let collector of collectorsData">{{collector.debtcollector_name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="d-block">
        <mat-label>Seleccione un cliente</mat-label>
        <mat-select [(ngModel)]="customer_dni" [ngModelOptions]="{standalone: true}">
          <mat-option [value]="customer.customer_dni" *ngFor="let customer of customersData">{{customer.customer_name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="inserRelation()">Añadir cliente</button>
    </mat-card-content>
  </mat-card>
</div>
