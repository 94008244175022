<div class="clearfix"><br /></div>
<h1 class="mx-auto text-center">Cobros</h1>

<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>Filtrar</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field appearance="fill" class="d-block">
        <mat-label>Seleccione un filtro</mat-label>
        <mat-select [(ngModel)]="filter_clients" [ngModelOptions]="{standalone: true}">
          <mat-option value="1">Nombre de cliente</mat-option>
          <mat-option value="2">Fecha de registro</mat-option>
          <mat-option value="3">Dia de pago</mat-option>
          <mat-option value="4">ID de cliente</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="d-block" *ngIf="filter_clients == 2">
        <mat-label>Introducir fecha</mat-label>
        <input matInput [matDatepicker]="dp3" [(ngModel)]="date_search" [ngModelOptions]="{standalone: true}" >
        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="d-block" *ngIf="filter_clients == 3">
        <mat-label>Introducir dia de cobro</mat-label>
        <mat-select [(ngModel)]="day" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let day of day_list" [value]="day">{{day}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="d-block" *ngIf="filter_clients == 1">
        <mat-label>
          Nombre de cliente
        </mat-label>
        <input [(ngModel)]="customer_name" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <mat-form-field class="d-block" *ngIf="filter_clients == 4">
        <mat-label>
          ID de cliente
        </mat-label>
        <input [(ngModel)]="customer_id" [ngModelOptions]="{standalone: true}" matInput>
      </mat-form-field>
      <button mat-flat-button color="primary" *ngIf="filter_clients == 1" class=" mx-auto mt-2 d-block" (click)="filterLoans()">Filtrar</button>
      <button mat-flat-button color="primary" *ngIf="filter_clients == 2" class="mx-auto mt-2 d-block" (click)="filterDateLoans()">Filtrar</button>
      <button mat-flat-button color="primary" *ngIf="filter_clients == 3" class="mx-auto mt-2 d-block" (click)="filterPayday()">Filtrar</button>
      <button mat-flat-button color="primary" *ngIf="filter_clients == 4" class="mx-auto mt-2 d-block" (click)="filterClientID()">Filtrar</button>
      <button mat-flat-button color="warn"  *ngIf="filter_clients" class="mx-auto mt-2 d-block" (click)="cancel()">Cancelar</button>

    </mat-card-content>
  </mat-card>
</div>
<div class="container mt-2">
    <div id="fancy-list-group">
      <div class="row">
          <div class="col-lg-12">
            <div *ngFor="let customer of customerData">
              <li class="list-group-item list-group-item-info">
                <div class="list-group-item-content">
                  <h4 class="list-group-item-heading">Nombre del cliente: {{ customer.customer_name }} {{ customer.customer_lastname }}</h4>
                  <p class="list-group-item-heading">ID del cliente: {{ customer.customer_id}}</p>
                  <p class="list-group-item-heading">Direccion: {{ customer.customer_direction }}</p>
                  <p class="list-group-item-heading">Cuota: {{ ((customer.debt > 0) ? customer.debt : customer.customer_fee) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                  <p class="list-group-item-heading">Dia de cobro: {{ customer.customer_payday }}</p>
                </div>
                <div class="list-group-item-controls">
                    <!--<button mat-button class="custom-button mx-auto mt-2" [routerLink]="['/' + typeCollector,'main','add-payment',loan.customer_dni]">Agregar pago</button>-->
                    <button mat-button class="custom-button mx-auto mt-2" [routerLink]="['/' + typeCollector,'main','add-payment',customer?.customer_id]">Detalles</button>
                </div>
              </li>
            </div>
          </div>
      </div>
    </div>
</div>
