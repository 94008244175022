<div class="text-center">
  <mat-form-field class="mx-2">
    <mat-label>
      Buscar
    </mat-label>
    <input  matInput
            (keyup)="applyFilter($event.target.value)">
  </mat-form-field>
</div>
<div class="container">
    <div id="fancy-list-group">
      <div class="row">
          <div class="col-lg-12">
            <li   class="list-group-item"
                  *ngFor="let loan of filteredData"
                  [class.list-group-item-info]="loan?.loan_status == 1"
                  [class.list-group-item-secondary]="loan?.loan_status == 0">
              <!--<div class="list-group-item-addon">
                <mat-icon>how_to_reg</mat-icon>
              </div>-->
              <div class="list-group-item-content">
                <h4 class="list-group-item-heading">Fecha de compra: {{ loan.loan_date | date }}</h4>
                <p class="list-group-item-heading">Concepto: {{ loan.loan_concept }}</p>
                <p class="list-group-item-heading">Cliente: {{ loan.customer_name }}</p>
                <p class="list-group-item-heading">ID cliente: {{ loan.customer_id }}</p>
                <p class="list-group-item-heading">Total de la compra: {{ loan.loan_totaldebt | currency : 'EUR' : 'symbol' : '1.0-0' }}</p>
              </div>
              <div class="list-group-item-controls">
                <span class="label mt-2">Acciones</span>
                <button mat-button class="custom-button-clients mb-2" [routerLink]="['/' + typeAdmin,'main','order-detail',loan.loan_id]"><mat-icon>add</mat-icon></button>
              </div>
            </li>

          </div>
      </div>
    </div>
</div>
