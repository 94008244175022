import { Component, OnInit } from '@angular/core';
import { GeneralService,TYPE_COLLECTOR,TYPE_ADMIN } from 'src/app/services/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  readonly typeCollector = TYPE_COLLECTOR;
  readonly typeAdmin = TYPE_ADMIN;

  public sideNavMode : string = 'side';

  constructor(public generalService: GeneralService, private router : Router) {}

  public ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.sideNavMode = 'over';
    }
  }
  logout() {
    this.generalService.setUserType('');
    this.router.navigate(['/login']);
  }
}
