<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>Registro de compra</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <!-- <mat-form-field appearance="fill" class="d-block">
          <mat-label>Seleccione un cliente</mat-label>
          <mat-select [(ngModel)]="customer_dni"
                      [ngModelOptions]="{standalone: true}">
            <mat-option [value]="customer.customer_dni"
                        *ngFor="let customer of customersData">{{customer.customer_name}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field class="d-block d-lg-inline-block col-lg-6">
          <mat-label>
            Cliente
          </mat-label>
          <input type="text"
                 matInput
                 [formControl]="customerControl"
                 [matAutocomplete]="auto">
         <mat-autocomplete #auto="matAutocomplete">
           <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.customer_id">
             {{ customer.customer_id }} - {{ customer.customer_name }} {{ customer.customer_lastname }}
           </mat-option>
         </mat-autocomplete>
         <mat-error *ngIf="customerControl.hasError('dni')">Debe seleccionar un cliente de la lista</mat-error>
        </mat-form-field>
        <span class="d-block d-lg-inline-block col-lg-6">{{ customerById[customerControl.value] }}</span>
        <mat-form-field appearance="fill"
                        class="d-block">
          <mat-label>Fecha de compra</mat-label>
          <input  matInput
                  [matDatepicker]="dp3"
                  [(ngModel)]="loan_date"
                  [ngModelOptions]="{standalone: true}" />
          <mat-datepicker-toggle  matSuffix
                                  [for]="dp3">
          </mat-datepicker-toggle>
          <mat-datepicker #dp3
                          disabled="false">
          </mat-datepicker>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Concepto
          </mat-label>
          <input [(ngModel)]="loan_concept" [ngModelOptions]="{standalone: true}" matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Total (€)
          </mat-label>
          <input type="number" [formControl]="totaldebtControl" matInput>
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="registerLoan()">Generar Compra</button>

    </mat-card-content>
  </mat-card>
</div>
