import { Component, OnInit } from '@angular/core';
import { GeneralService, TYPE_ADMIN, TYPE_COLLECTOR } from 'src/app/services/general.service';
import { Customer } from 'src/app/classes/customer';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-clients',
  templateUrl: './admin-clients.component.html',
  styleUrls: ['./admin-clients.component.scss']
})
export class AdminClientsComponent implements OnInit {
  installmentData : any = {};
  customersData : Customer[] = [];
  filteredData : Customer[] = [];
  readonly typeAdmin = TYPE_ADMIN ;
  readonly typeCollector = TYPE_COLLECTOR;
  mobile : boolean;
  customerName : string;
  constructor(private generalService : GeneralService) {
    if (this.generalService.getUserType() == this.typeAdmin) {
      this.loadCustomers();
    } else if (this.generalService.getUserType() == this.typeCollector) {
      this.loadCustomersCollector();
    }

  }
  loadCustomers() {
    this.generalService.getCustomers().subscribe( responseCustomers => {
      console.log(responseCustomers);

      if(responseCustomers[1] == "no result"){
        Swal.fire('No se han cargado clientes','','info');
      }else{
        this.customersData = responseCustomers[1];
        this.filteredData = this.customersData.filter(item => true);
        this.customersData.forEach(({ customer_dni }) => this.loadCustomerInstallments(customer_dni));
      }
    });
  }

  loadCustomerInstallments(customer_dni : string) : void {
    this.generalService.getInstallments(customer_dni).subscribe(response => {
      console.log(response);

      if (response[0] == false) {
        this.generalService.showMessage('Error inesperado', '', 'error');
        return;
      }

      if (response[1] != "no result") {
        this.installmentData[customer_dni] = response[1][0];
      }

    });
  }

  loadCustomersCollector() {
    this.generalService.getCustomerCollector(this.generalService.getUserId()).subscribe( responseCustomers => {
      if(responseCustomers[1] == "no result"){
        Swal.fire('No se han cargado clientes','','info');
      }else{
        this.customersData = responseCustomers[1];
        this.filteredData = this.customersData.filter(item => true);
      }
    });
  }
  getCustomerName() {
    this.generalService.getCustomerByName(this.customerName).subscribe( responseCustomers => {
      console.log(responseCustomers)
      if (responseCustomers[1] == "no result") {
        Swal.fire('Cliente no encontrado','','info');
      } else {
        this.customersData = responseCustomers[1];
        this.filteredData = this.customersData.filter(item => true);
      }
    });
  }

  applyFilter(filter) : void {
    if (filter.length <= 0) {
      this.filteredData = this.customersData.filter(item => true);
      return;
    }
    this.filteredData = this.customersData.filter(item => {
      const json = JSON.stringify(item).toLowerCase();
      return json.includes(filter.toLowerCase());
    });
  }

  ngOnInit(): void {
    if (window.screen.width === 360) {
    this.mobile = true;
    }
  }

}
