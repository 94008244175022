import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { AdminClientsComponent } from './components/admin/admin-clients/admin-clients.component';
import { AdminCollectorsComponent } from './components/admin/admin-collectors/admin-collectors.component';
import { AdminLoansComponent } from './components/mod/admin-loans/admin-loans.component';
import { AdminOrdersComponent } from './components/admin/admin-orders/admin-orders.component';
import { RegisterCollectorComponent } from './components/admin/register-collector/register-collector.component';
import { RegisterCustomerComponent } from './components/admin/register-customer/register-customer.component';
import { CollectorDetailsComponent } from './components/admin/collector-details/collector-details.component';
import { ClientDetailsComponent } from './components/admin/client-details/client-details.component';
import { EditCollectorComponent } from './components/admin/edit-collector/edit-collector.component';
import { EditClientComponent } from './components/admin/edit-client/edit-client.component';
import { RegisterOrderComponent } from './components/admin/register-order/register-order.component';
import { OrderDetailsComponent } from './components/admin/order-details/order-details.component';
import { AddPaymentComponent } from './components/mod/add-payment/add-payment.component';
import { HomeComponent } from './components/home/home.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { AddClientsComponent } from './components/admin/add-clients/add-clients.component';
import { LoginGuard } from './guards/login.guard';
const routes: Routes = [
  { path: ':type/main', component: MainComponent,canActivate: [LoginGuard], children: [
    { path: 'admin-clients', component: AdminClientsComponent },
    { path: 'admin-collectors', component: AdminCollectorsComponent},
    { path: 'admin-loans', component: AdminLoansComponent },
    { path: 'admin-orders', component: AdminOrdersComponent },
    { path: 'register-customer', component: RegisterCustomerComponent },
    { path: 'register-collector', component: RegisterCollectorComponent },
    { path: 'collector-detail/:id', component: CollectorDetailsComponent },
    { path: 'client-detail/:id', component: ClientDetailsComponent },
    { path: 'edit-collector/:id', component: EditCollectorComponent },
    { path: 'edit-client/:id', component: EditClientComponent },
    { path: 'register-order', component: RegisterOrderComponent },
    { path: 'order-detail/:id', component: OrderDetailsComponent },
    { path: 'add-payment/:id', component: AddPaymentComponent },
    { path: 'statistics', component: StatisticsComponent },
    { path: 'add-clients', component: AddClientsComponent },
    { path: 'home', component: HomeComponent }
  ] },
  { path: 'login', component: LoginComponent },
  { path: '**', pathMatch: 'full', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
