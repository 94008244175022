import { Component, OnInit } from '@angular/core';
import { Collector } from 'src/app/classes/collector';
import { GeneralService, TYPE_ADMIN } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-collectors',
  templateUrl: './admin-collectors.component.html',
  styleUrls: ['./admin-collectors.component.scss']
})
export class AdminCollectorsComponent implements OnInit {
  collectorsData : Collector[] = [];
  readonly typeAdmin = TYPE_ADMIN;
  mobile : boolean;

  constructor(private generalService: GeneralService) {
    this.loadCollectors();

  }
  loadCollectors(){
    this.generalService.getCollectors().subscribe( responseCollectors => {
      if(responseCollectors[1] == "no result"){
        Swal.fire('No se han cargado cobradores','','info');
      }else{
        this.collectorsData = responseCollectors[1];
        console.log(responseCollectors);
      }
    });
  }

  ngOnInit(): void {
    if (window.screen.width === 360) {
    this.mobile = true;
    }
  }
}
