<div class="container">
    <div id="fancy-list-group">
      <div class="row">
          <div class="col-lg-12">
            <li class="list-group-item list-group-item-success" *ngFor="let collector of collectorsData">
              <div class="list-group-item-content">
                <h3 class="list-group-item-heading font-weight-bold">{{ collector.debtcollector_name }} {{ collector.debtcollector_lastname }}</h3>
                <p class="list-group-item-heading">DNI: {{ ((collector.debtcollector_dni == '') ? 'No especificado' : collector.debtcollector_dni) }}</p>
                <p class="list-group-item-heading">Email: {{ collector.debtcollector_email }}</p>
                <p class="list-group-item-text">Telefono: {{collector.debtcollector_phonenumber}}</p>
              </div>
              <div class="list-group-item-controls">
                <span class="label mt-2">Acciones</span>
                  <button mat-button class="custom-button-clients" [class.mr-2]="!mobile" [routerLink]="['/' + typeAdmin,'main','edit-collector',collector.debtcollector_dni]"><mat-icon>edit</mat-icon></button>
                  <button mat-button class="custom-button-clients" [class.mr-2]="!mobile"><mat-icon>delete</mat-icon></button>
                  <button mat-button class="custom-button-clients" [routerLink]="['/' + typeAdmin,'main','collector-detail',collector.debtcollector_dni]"><mat-icon>add</mat-icon></button>
              </div>
            </li>
          </div>
      </div>
    </div>
</div>
