import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LoginValue } from './../interfaces/form-interfaces';

import { Response } from './../classes/response';

import { HOSTNAME } from './../../environments/environment';
import Swal from 'sweetalert2';

export const TYPE_COLLECTOR = "collector";
export const TYPE_ADMIN = "admin";

// Esta direccion es provisional
const LOGIN_URL = `${HOSTNAME}/login.php`;

export const SERVER_URL = 'https://jjruiz.es/';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  private userId : string = '';
  private userType: string ='';

  userIdChange : EventEmitter<string> = new EventEmitter<string>();
  userTypeChange : EventEmitter<string> = new EventEmitter<string>();

  private httpOptions = {
    headers: new HttpHeaders()
  }

  private serviceUrl : string = 'https://jjruiz.es/service';
  //private serviceUrl : string = 'http://localhost/angular/loan-collector/service';

  url_admin : string = `${this.serviceUrl}/generaloperations/admin.php`;
  url_debtCollector : string = `${this.serviceUrl}/generaloperations/debtcollector.php`;
  url_customer : string = `${this.serviceUrl}/generaloperations/customer.php`;
  url_loan : string = `${this.serviceUrl}/generaloperations/loan.php`;
  url_installment : string = `${this.serviceUrl}/generaloperations/installments.php`;
  url_fee : string = `${this.serviceUrl}/generaloperations/fee.php`;
  url_date : string = `${this.serviceUrl}/generaloperations/get_date.php`;

  constructor(private http : HttpClient) { }

  /*public login(formValue : LoginValue) : Observable<Response> {
    return this.http.post<Response>(LOGIN_URL, formValue);
  }*/

  getUserType(){
    return this.userType;
  }

  setUserType(userType : string) : void {
    this.userType = userType;
    this.userTypeChange.emit(userType)
  }

  getUserId() : string {
    return this.userId;
  }

  setUserId(userId : string) : void {
    this.userId = userId;
    this.userIdChange.emit(userId);
  }

  login({username, password, userType}) : Observable<any> | null {
    console.log(userType);

    switch (userType) {

      case TYPE_COLLECTOR: {
        return this.loginCollector(username, password);
        break;
      }
      case TYPE_ADMIN: {
        return this.loginAdmin(username, password);
        break;
      }
      default: {
        return null;
        break;
      }
    }
  }

  checkSession(token : string, userType : string) : Observable<boolean> {
    console.log('checkSession', userType);

    switch (userType) {
      case TYPE_COLLECTOR: {
        return this.checkCollectorSession(token);
        break;
      }
      case TYPE_ADMIN: {
        return this.checkAdminSession(token);
        break;
      }
      default: {
        console.log("Invalid session type");
        return of(false);
        break;
      }
    }
  }

  private loginCollector(collectorUsername : string, collectorPassword : string) : Observable<any> {
    const url = `${this.url_debtCollector}?action=checkdebtcollector`;

    let formData : FormData = new FormData();
    formData.append('debtcollector_email', collectorUsername);
    formData.append('debtcollector_password', collectorPassword);

    return this.http.post<any>(url, formData);

  }

  private checkCollectorSession(token : string) : Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      console.log("checkCollectorSession");
      if (token == null) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      this.httpOptions.headers = new HttpHeaders();
      this.httpOptions.headers = this.httpOptions.headers.append('Authorization', token);

      const url = `${this.url_debtCollector}?action=verify`;
      this.http.post<any>(url, null, this.httpOptions).subscribe(response => {
        console.log(response);

        if (response == "expired session" || response == "unexpected error"
          || response == "no session") {

          sessionStorage.removeItem('session_token');
          subscriber.next(false);
          subscriber.complete();
          return;
        }

        this.setUserId(response);
        this.setUserType(TYPE_COLLECTOR);
        subscriber.next(true);
        subscriber.complete();
      });
    });
  }

  private loginAdmin(adminUsername : string, adminPassword : string) : Observable<any> {
    const url = `${this.url_admin}?action=checkadmin`;

    let formData : FormData = new FormData();
    formData.append('admin_email', adminUsername);
    formData.append('admin_password', adminPassword);

    return this.http.post<any>(url, formData);
  }

  private checkAdminSession(token : string) : Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      console.log("checkAdminSession");
      if (token == null) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      this.httpOptions.headers = new HttpHeaders();
      this.httpOptions.headers = this.httpOptions.headers.append('Authorization', token);

      const url = `${this.url_admin}?action=verify`;
      this.http.post<any>(url, null, this.httpOptions).subscribe(response => {
        if(isNaN(response)) {
          sessionStorage.removeItem('session_token');
          subscriber.next(false);
          subscriber.complete();
          return;
        }
        this.setUserId(response);
        this.setUserType(TYPE_ADMIN);
        subscriber.next(true);
        subscriber.complete();
      });
    });
  }
  public showMessage(aux : any, text? : string, icon? : any) : Promise<any> {
    if (typeof aux == 'object') {
      return Swal.fire({
        ...aux,
        allowEscapeKey : false,
        allowOutsideClick: false,
        heightAuto: false
      });
    }
    return Swal.fire({
      title: aux,
      text,
      icon,
      allowEscapeKey : false,
      allowOutsideClick: false,
      heightAuto: false
    });
  }

  registerCollector({ email, password, phonenumber, dni, name, lastName })  {
    let formData : FormData = new FormData();
    formData.append('debtcollector_email', email);
    formData.append('debtcollector_name', name);
    formData.append('debtcollector_lastname', lastName);
    formData.append('debtcollector_password', password);
    formData.append('debtcollector_phonenumber', phonenumber);
    formData.append('debtcollector_dni', dni);
    return this.http
      .post(`${this.url_debtCollector}?action=insert`, formData);
  }

  insertRelation( customer_id : string, debtcollector_dni : string )  {
    let formData : FormData = new FormData();
    formData.append('customer_id', customer_id);
    formData.append('debtcollector_dni', debtcollector_dni);
    return this.http
      .post(`${this.url_debtCollector}?action=insertrelation`, formData);
  }

  deleteRelation(customer_id : string, debtcollector_dni : string) {
    let formData : FormData = new FormData();
    formData.append('customer_id', customer_id);
    formData.append('debtcollector_dni', debtcollector_dni);
    return this.http
      .post(`${this.url_debtCollector}?action=deleterelation`, formData);
  }

  getCollector(collector_dni : string) {
    const url = `${this.url_debtCollector}?action=get&debtcollector_dni=${collector_dni}`;
    return this.http.get<any>(url);
  }

  getCollectors(){
    const url = `${this.url_debtCollector}?action=getall`;
    return this.http.get<any>(url);
  }

  updateCollector(debtcollector_name : string,debtcollector_lastname : string,debtcollector_dni : string, debtcollector_phonenumber : string,
    debtcollector_password? : string) {

    let formData : FormData = new FormData();
    formData.append('debtcollector_name', debtcollector_name);
    formData.append('debtcollector_lastname', debtcollector_lastname);
    formData.append('debtcollector_dni', debtcollector_dni);
    formData.append('debtcollector_phonenumber', debtcollector_phonenumber);

    if(debtcollector_password != null){
      formData.append('debtcollector_password', debtcollector_password);
    }

    return this.http
      .post(`${this.url_debtCollector}?action=update`, formData);

  }

  registerCustomer({ email, dni, id, guarantee, phone, name, lastname, address, payday, paydate,fee, register })  {
    let formData : FormData = new FormData();
    formData.append('customer_email', email);
    formData.append('customer_dni', dni);
    formData.append('customer_id', id);
    formData.append('customer_guarantee', guarantee);
    formData.append('customer_phonenumber', phone);
    formData.append('customer_name', name);
    formData.append('customer_lastname', lastname );
    formData.append('customer_direction', address );
    formData.append('customer_payday', payday);
    formData.append('customer_paydate', paydate);
    formData.append('customer_fee', fee);
    formData.append('customer_register', register);

    return this.http
      .post(`${this.url_customer}?action=insert`, formData);
  }

  getCustomer(customer_id : string, debtcollector_dni? : string) {
    let url = `${this.url_customer}?action=get&customer_id=${customer_id}`;
    if (debtcollector_dni != null) {
      url += `&debtcollector_dni=${debtcollector_dni}`;
    }
    return this.http.get<any>(url);
  }
  getCustomerById(customer_id : string, debtcollector_dni? : string) {
    let url = `${this.url_customer}?action=get&customer_id=${customer_id}`;
    if (debtcollector_dni != null) {
      url += `&debtcollector_dni=${debtcollector_dni}`;
    }
    return this.http.get<any>(url);
  }
  getCustomerByName(customer_name : string, debtcollector_dni? : string) : Observable<any> {
    let url = `${this.url_customer}?action=get&customer_name=${customer_name}`;
    if (debtcollector_dni != null) {
      url += `&debtcollector_dni=${debtcollector_dni}`;
    }
    return this.http.get<any>(url);
  }
  getCustomerPayday(customer_payday : number, debtcollector_dni? : string) : Observable<any> {
    let url = `${this.url_customer}?action=get&customer_payday=${customer_payday}`;
    if (debtcollector_dni != null) {
      url += `&debtcollector_dni=${debtcollector_dni}`;
    }
    return this.http.get<any>(url);
  }
  getCustomerRegister(customer_register : string, debtcollector_dni? : string) : Observable<any>{
    let url = `${this.url_customer}?action=get&customer_register=${customer_register}`;
    if (debtcollector_dni != null) {
      url += `&debtcollector_dni=${debtcollector_dni}`;
    }
    return this.http.get<any>(url);
  }
  getCustomers() : Observable<any> {
    const url = `${this.url_customer}?action=getall`;
    return this.http.get<any>(url);
  }
  getCustomerCollector(debtcollector_dni : string) : Observable<any> {
    const url = `${this.url_customer}?action=get&debtcollector_dni=${debtcollector_dni}`;
    return this.http.get<any>(url);
  }

  updateCustomer(customer_dni : string, customer_name : string,
    customer_lastname : string, customer_email : string, customer_phonenumber : string,
    customer_direction : string, customer_payday : number, customer_id : string,
    customer_register : string, customer_paydate : string) {

    console.log(customer_id);


    let formData : FormData = new FormData();
    formData.append('customer_id', customer_id);
    formData.append('customer_dni', customer_dni);
    formData.append('customer_name', customer_name);
    formData.append('customer_lastname', customer_lastname);
    formData.append('customer_email', customer_email);
    formData.append('customer_phonenumber', customer_phonenumber);
    formData.append('customer_direction', customer_direction);
    formData.append('customer_payday', customer_payday.toString());
    formData.append('customer_paydate', customer_paydate);

    return this.http
      .post(`${this.url_customer}?action=update`, formData);
  }

  updateCustomerPaydate(customer_id : string,customer_paydate : string) {

    let formData : FormData = new FormData();
    formData.append('customer_id', customer_id);
    formData.append('customer_paydate', customer_paydate);
    //formData.append('customer_payday', customer_payday);

    return this.http
      .post(`${this.url_customer}?action=update`, formData);
  }

  registerLoan( loan_totaldebt : string, loan_concept : string,
             customer_id : string, loan_date : string )  {
    let formData : FormData = new FormData();
    formData.append('loan_totaldebt', loan_totaldebt);
    formData.append('loan_concept', loan_concept);
    formData.append('customer_id', customer_id);
    formData.append('loan_date', loan_date);

    return this.http
      .post(`${this.url_loan}?action=insert`, formData);
  }

  getLoan(customer_id : string) {
    const url = `${this.url_loan}?action=get&customer_id=${customer_id}`;
    return this.http.get<any>(url);
  }

  getCustomerActiveLoan(customer_id : string) : Observable<any> {
    const url = `${this.url_loan}?action=get&customer_id=${customer_id}&loan_status=1`;
    return this.http.get<any>(url);
  }

  getLoanById(loan_id : string){
    const url = `${this.url_loan}?action=get&loan_id=${loan_id}`;
    return this.http.get<any>(url);
  }
  getLoanCollector(debtcollector_dni : string){
    const url = `${this.url_loan}?action=get&debtcollector_dni=${debtcollector_dni}`;
    return this.http.get<any>(url);
  }
  getLoans(){
    const url = `${this.url_loan}?action=getall`;
    return this.http.get<any>(url);
  }

  updateLoan(loan_id : number, loan_totaldebt : number, loan_date : string,
    loan_concept : string) {

    console.log(loan_id, loan_date, loan_concept, loan_totaldebt);

    let formData : FormData = new FormData();
    formData.append('loan_id', loan_id.toString());
    formData.append('loan_date', loan_date);
    formData.append('loan_concept', loan_concept);
    formData.append('loan_totaldebt', loan_totaldebt.toString());

    return this.http
      .post(`${this.url_loan}?action=update`, formData);

  }

  registerInstallment( installments_date : string, installments_payment : number,
             customer_id : string, installments_debt : number )  {
    let formData : FormData = new FormData();
    formData.append('installments_date', installments_date);
    formData.append('installments_payment', installments_payment.toString());
    formData.append('customer_id', customer_id);
    formData.append('installments_debt', installments_debt.toString());

    return this.http
      .post(`${this.url_installment}?action=insert`, formData);
  }

  getInstallments(customer_id : string) {
    const url = `${this.url_installment}?action=get&customer_id=${customer_id}`;
    return this.http.get<any>(url);
  }
  getInstallmentsById(customer_id : string) {
    const url = `${this.url_installment}?action=get&customer_id=${customer_id}`;
    return this.http.get<any>(url);
  }
  getInstallmentsByRange(start : string, end : string) {
    const url = `${this.url_installment}?action=get&start=${start}&end=${end}`;
    return this.http.get<any>(url);
  }

  updateInstallment(installments_id : string, installments_payment : string, installments_date : string, installments_debt : string) {

    let formData : FormData = new FormData();
    formData.append('installments_id', installments_id);
    formData.append('installments_payment', installments_payment);
    formData.append('installments_debt', installments_debt);
    formData.append('installments_date', installments_date);

    return this.http
      .post(`${this.url_installment}?action=update`, formData);
  }

  updateFee({ fee, message }, customer_id) : Observable<any> {
    let formData : FormData = new FormData();
    formData.append('customer_id', customer_id);
    formData.append('customer_fee', fee);
    formData.append('customer_message', message);

    return this.http
      .post(`${this.url_customer}?action=update`, formData);
  }

  getDate() : Observable<any> {
    return this.http.get(this.url_date);
  }

  setLoanStatus(loan_id : string, loan_status : string) : Observable<any> {
    let formData : FormData = new FormData();
    formData.append('loan_id', loan_id);
    formData.append('loan_status', loan_status);

    return this.http.post(`${this.url_loan}?action=update`, formData);
  }

  formatDate(date : Date) : string {
    console.log(date);

    let y : string = `${date.getFullYear()}`;
    let m : string = `${date.getMonth() + 1}`;
    let d : string = `${date.getDate()}`;
    let result : string = `${y}-${(m.length < 2) ? '0' + m : m}-${(d.length < 2) ? '0' + d : d}`;
    return result;
  }
}
