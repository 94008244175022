<div class="d-flex justify-content-center align-items-center row">
  <mat-card class="col-12 col-md-8">
    <mat-card-header>
      <mat-card-title>
        Detalles de cliente
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ul class="list-group list-group-flush text-left">
        <li class="list-group-item">
          <span class="font-weight-bold">
            Nombre de cliente
          </span>
          <span class="float-right">{{ customerData?.customer_name }} {{ customerData?.customer_lastname }}</span>
        </li>
        <li class="list-group-item">
          <span class="font-weight-bold">
            Domicilio de cliente
          </span>
          <span class="float-right">{{ customerData?.customer_direction }}</span>
        </li>
        <li class="list-group-item">
          <span class="font-weight-bold">
            Cuota de cliente
          </span>
          <span class="float-right">{{ customerData?.customer_fee | currency : 'EUR' : 'symbol' : '1.0-0' }}</span>
        </li>
      </ul>
      <div *ngIf="userType == typeAdmin" class="text-center">
        <button mat-flat-button
                class="my-3"
                type="button"
                color="primary"
                (click)="openAddDialog()">
          <mat-icon class="mr-2">add</mat-icon>
          Agregar pago
        </button>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="col-12 col-md-8">
    <button mat-flat-button color="warn" class="mx-auto d-block mt-2" (click)="back()">Volver</button>
  </div>
</div>

<div class="d-flex justify-content-center align-items-center row my-5">
  <mat-card class="col-12 col-md-8">
    <mat-card-header>
      <mat-card-title>
        Pagos del cliente
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div  *ngIf="installments.length <= 0"
            class="text-center lead">
        No hay pagos actualmente
      </div>
      <ul   class="list-group my-2"
            *ngFor="let installment of installments; let i = index">
        <li class="list-group-item lead text-center"
            *ngIf="editing[i] == false">
          <span>
            Pago de fecha {{ installment.installments_date | date }}
          </span>
          <button mat-icon-button
                  class="float-right mx-2"
                  (click)="editing[i] = true"
                  *ngIf="userType == typeAdmin"><mat-icon>edit</mat-icon></button>
          <button mat-icon-button
                  [matMenuTriggerFor]="menu"
                  class="float-right mx-2"><mat-icon>description</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item
                    (click)="goToPDF(installment.installments_id)">
              <mat-icon>account_box</mat-icon>
              <span>Generar PDF</span>
            </button>
            <button mat-menu-item>
              <mat-icon>mail</mat-icon>
              <span>Enviar correo a cliente</span>
            </button>
          </mat-menu>
        </li>
        <li class="list-group-item"
            *ngIf="editing[i] == false">
          <span class="font-weight-bold">
            Cuota en el momento de pago
          </span>
          <span class="float-right">
            {{ installment.installments_debt | currency : 'EUR' : 'symbol' : '1.0-0' }}
          </span>
        </li>
        <li class="list-group-item"
            *ngIf="editing[i] == false">
          <span class="font-weight-bold">
            Cantidad pagada
          </span>
          <span class="float-right">
            {{ installment.installments_payment | currency : 'EUR' : 'symbol' : '1.0-0' }}
          </span>
        </li>
        <li class="list-group-item"
            *ngIf="editing[i] == true">
          <mat-form-field>
            <mat-label>Fecha de pago</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="installment.installments_date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </li>
        <li class="list-group-item"
            *ngIf="editing[i] == true">
          <mat-form-field>
            <mat-label>Cuota en el momento de pago</mat-label>
            <input  matInput
                    [(ngModel)]="installment.installments_debt">
          </mat-form-field> €
        </li>
        <li class="list-group-item"
            *ngIf="editing[i] == true">
          <mat-form-field>
            <mat-label>Cuota pagada</mat-label>
            <input  matInput
                    placeholder="Cuota pagada"
                    [(ngModel)]="installment.installments_payment">
          </mat-form-field> €
        </li>
        <li class="list-group-item"
            *ngIf="editing[i] == true">
          <button mat-flat-button
                  color="primary"
                  class="mx-2"
                  (click)="updateInstallment(i)">Guardar</button>
          <button mat-flat-button
                  color="warn"
                  class="mx-2"
                  (click)="editing[i] = false">Cancelar</button>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>


</div>
