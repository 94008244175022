<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>Registro de cliente</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="customerFormGroup"
            (ngSubmit)="registerCustomer()">
        <mat-form-field class="d-block">
          <mat-label>
            Nombre
          </mat-label>
          <input  formControlName="name"
                  matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Apellido
          </mat-label>
          <input  formControlName="lastname"
                  matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            DNI
          </mat-label>
          <input  formControlName="dni"
                  matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            ID
          </mat-label>
          <input  formControlName="id"
                  matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Dirección
          </mat-label>
          <input  formControlName="address"
                  matInput>
        </mat-form-field>
        <!--<mat-form-field class="d-block">
          <mat-label>
            Dia de cobro
          </mat-label>
          <input  formControlName="payday"
                  type="number"
                  matInput>
        </mat-form-field>-->
        <mat-form-field appearance="fill" 
                        class="d-block">
          <mat-label>Dia de cobro</mat-label>
          <input  matInput
                  [matDatepicker]="dpPayday"
                  formControlName="paydate" />
          <mat-datepicker-toggle  matSuffix
                                  [for]="dpPayday">
          </mat-datepicker-toggle>
          <mat-datepicker #dpPayday
                          disabled="false">
          </mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" 
                        class="d-block">
          <mat-label>Fecha de registro</mat-label>
          <input  matInput
                  [matDatepicker]="dp3"
                  formControlName="register" />
          <mat-datepicker-toggle  matSuffix
                                  [for]="dp3">
          </mat-datepicker-toggle>
          <mat-datepicker #dp3
                          disabled="false">
          </mat-datepicker>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Avalista
          </mat-label>
          <input formControlName="guarantee"
                  matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Cuota (en Euros)
          </mat-label>
          <input  formControlName="fee"
                  matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Email
          </mat-label>
          <input  formControlName="email"
                  matInput>
        </mat-form-field>
        <mat-form-field class="d-block">
          <mat-label>
            Telefono
          </mat-label>
          <input  formControlName="phone"
                  matInput>
        </mat-form-field>
        <button mat-flat-button color="primary">Registrar cliente</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
