<mat-sidenav-container class="h-100">
  <mat-sidenav  #sidenav
                [mode]="sideNavMode"
                color="accent"
                opened
                class="sidenav">
    <mat-nav-list>
      <mat-list-item *ngIf="generalService.getUserType() == 'admin'" [routerLink]="['/' + typeAdmin,'main','statistics']">
        <mat-icon class="mr-2">home</mat-icon>
        Inicio
      </mat-list-item>
      <mat-list-item *ngIf="generalService.getUserType() == 'collector'" [routerLink]="['/' + typeCollector,'main','home']">
        <mat-icon class="mr-2">home</mat-icon>
        Inicio
      </mat-list-item>
      <mat-list-item *ngIf="generalService.getUserType() == 'admin'"  [routerLink]="['/' + typeAdmin,'main','register-customer']">
        <mat-icon class="mr-2">person_add</mat-icon>
        Registrar clientes
      </mat-list-item>
      <mat-list-item *ngIf="generalService.getUserType() == 'admin'"  [routerLink]="['/' + typeAdmin,'main','register-collector']">
        <mat-icon class="mr-2">enhanced_encryption</mat-icon>
        Registrar cobradores
      </mat-list-item>
      <mat-list-item *ngIf="generalService.getUserType() == 'admin'"  [routerLink]="['/' + typeAdmin,'main','admin-clients']">
        <mat-icon class="mr-2">account_box</mat-icon>
        Administrar clientes
      </mat-list-item>
      <!-- <mat-list-item *ngIf="generalService.getUserType() == 'collector'"  [routerLink]="['/' + typeCollector,'main','admin-clients']">
        <mat-icon class="mr-2">account_box</mat-icon>
        Administrar clientes
      </mat-list-item> -->
      <mat-list-item *ngIf="generalService.getUserType() == 'admin'"  [routerLink]="['/' + typeAdmin,'main','admin-collectors']">
        <mat-icon class="mr-2">shopping_bag</mat-icon>
        Administrar cobradores
      </mat-list-item>
      <mat-list-item  *ngIf="generalService.getUserType() == 'admin'" [routerLink]="['/' + typeAdmin,'main','admin-orders']">
        <mat-icon class="mr-2">shopping_cart</mat-icon>
        Administrar compras
      </mat-list-item>
      <mat-list-item  *ngIf="generalService.getUserType() == 'admin'" [routerLink]="['/' + typeAdmin,'main','register-order']">
        <mat-icon class="mr-2">add_shopping_cart</mat-icon>
        Registrar compra
      </mat-list-item>
      <!--<mat-list-item  *ngIf="generalService.getUserType() == 'admin'" [routerLink]="['/' + typeAdmin,'main','add-clients']">
        <mat-icon class="mr-2">groups</mat-icon>
        Añadir clientes a cobradores
      </mat-list-item>-->
      <mat-list-item  *ngIf="generalService.getUserType() == 'collector'" [routerLink]="['/' + typeCollector,'main','admin-loans']">
        <mat-icon class="mr-2">view_list</mat-icon>
        Administrar cobros
      </mat-list-item>
      <mat-list-item (click)="logout()">
        <mat-icon class="mr-2">lock</mat-icon>
        Cerrar sesión
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar  color="primary"
                  class="mb-5">
     <button  mat-icon-button
              (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span>Loan collector</span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
