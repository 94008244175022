<div class="d-flex justify-content-center align-items-center">
  <mat-card class="col-12 col-md-8 col-xl-4 text-center">
    <mat-card-header>
      <mat-card-title>Detalles de cliente</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="d-block">
        <mat-label>
          ID
        </mat-label>
        <input  [(ngModel)]="customer_id"
                [ngModelOptions]="{standalone: true}"
                matInput
                disabled>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>Fecha de regsitro</mat-label>
        <input  matInput
                [matDatepicker]="picker"
                [(ngModel)]="customer_register"
                [ngModelOptions]="{standalone: true}">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <!-- <mat-form-field class="d-block">
        <mat-label>
          Fecha de registro
        </mat-label>
        <input  [(ngModel)]="customer_register"
                [ngModelOptions]="{standalone: true}"
                matInput>
      </mat-form-field> -->
      <mat-form-field class="d-block">
        <mat-label>
          Nombre
        </mat-label>
        <input  [(ngModel)]="customer_name"
                [ngModelOptions]="{standalone: true}"
                matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Apellido
        </mat-label>
        <input  [(ngModel)]="customer_lastname"
                [ngModelOptions]="{standalone: true}"
                matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          DNI
        </mat-label>
        <input  [(ngModel)]="customer_dni"
                [ngModelOptions]="{standalone: true}"
                matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Dirección
        </mat-label>
        <input  [(ngModel)]="customer_direction"
                [ngModelOptions]="{standalone: true}"
                matInput>
      </mat-form-field>
      <!--<mat-form-field class="d-block">
        <mat-label>
          Dia de cobro
        </mat-label>
        <input  [(ngModel)]="customer_payday" [ngModelOptions]="{standalone: true}"
                min="1"
                max="31"
                type="number"
                matInput>
      </mat-form-field>-->
      <mat-form-field class="d-block">
        <mat-label>Dia de cobro</mat-label>
        <input  matInput
                [matDatepicker]="pickerPayDate"
                [(ngModel)]="customer_paydate"
                [ngModelOptions]="{standalone: true}">
        <mat-datepicker-toggle matSuffix [for]="pickerPayDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerPayDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Email
        </mat-label>
        <input  [(ngModel)]="customer_email"
                [ngModelOptions]="{standalone: true}"
                matInput>
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>
          Telefono
        </mat-label>
        <input  [(ngModel)]="customer_phonenumber"
                [ngModelOptions]="{standalone: true}"
                matInput>
      </mat-form-field>
      <button mat-flat-button
              color="primary"
              class="mr-1 mb-1"
              (click)="updateClient()">Editar cliente</button>
      <button mat-flat-button
              color="warn"
              class="mr-2"
              (click)="back()">Volver</button>
    </mat-card-content>
  </mat-card>
</div>
