<div class="text-center">
  <mat-form-field class="mx-2">
    <mat-label>
      Buscar
    </mat-label>
    <input  matInput
            (keyup)="applyFilter($event.target.value)">
  </mat-form-field>
  <span class="float-right mr-5">
    Clientes registrados: {{ customersData?.length }}
  </span>
</div>
<div class="container">
    <div id="fancy-list-group">
      <div class="row">
          <div class="col-lg-12">
            <li class="list-group-item" 
                *ngFor="let customer of filteredData"
                [class.list-group-item-info]="customer?.customer_debt - customer?.customer_paid > 0"
                [class.list-group-item-secondary]="customer?.customer_debt - customer?.customer_paid <= 0">
              <div class="list-group-item-content">
                <h3 class="list-group-item-heading font-weight-bold">{{ customer.customer_name }} {{ customer.customer_lastname }}</h3>
                <span class="float-right text-left font-weight-bold">
                  <p *ngIf="installmentData[customer.customer_dni] != null">Ultimo pago: {{ installmentData[customer.customer_dni]?.installments_payment | currency : 'EUR' : 'symbol' : '1.0-0' }} / {{ installmentData[customer.customer_dni]?.installments_debt | currency : 'EUR' : 'symbol' : '1.0-0' }}</p>
                  <p class="text-danger">Deuda total: {{ (customer?.customer_debt) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                  <p class="text-success">Total pagado: {{ (customer?.customer_paid) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                  <p class="text-secondary">Pendiente: {{ (customer?.customer_debt - customer?.customer_paid) | currency : "EUR" : "symbol" : '1.0-0' }}</p>
                </span>
                <p class="list-group-item-heading">ID: {{ customer.customer_id }}</p>
                <p class="list-group-item-heading">Domicilio: {{ customer.customer_direction }}</p>
                <p class="list-group-item-heading">Telefono: {{ customer.customer_phonenumber }}</p>
                <p class="list-group-item-text">Dia de cobro: {{ customer.customer_payday }}</p>
                <p class="list-group-item-text">Cuota: {{ customer.customer_fee | currency : 'EUR' : 'symbol' : '1.0-0' }}</p>
              </div>
              <div class="list-group-item-controls">
                <span class="label mt-2">Acciones</span>
                <button *ngIf="generalService.getUserType()  == typeCollector " mat-button class="custom-button mx-auto mt-2" [routerLink]="['/' + typeCollector,'main','add-payment',customer.customer_id]">Cuotas de pago</button>
                <button *ngIf="generalService.getUserType()  == typeAdmin " mat-button class="custom-button-clients" [class.mr-2]="!mobile"   [routerLink]="['/' + typeAdmin,'main','edit-client',customer.customer_id]"><mat-icon>edit</mat-icon></button>
                <button *ngIf="generalService.getUserType()  == typeAdmin " mat-button class="custom-button-clients" [class.mr-2]="!mobile"><mat-icon>delete</mat-icon></button>
                <button *ngIf="generalService.getUserType()  == typeAdmin " mat-button class="custom-button-clients" [routerLink]="['/' + typeAdmin,'main','client-detail',customer.customer_id]"><mat-icon>add</mat-icon></button>
              </div>
            </li>

          </div>
      </div>
    </div>
</div>
