import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GeneralService,TYPE_COLLECTOR,TYPE_ADMIN} from './../services/general.service';
import Swal from 'sweetalert2';

const COLLECTOR_ROUTES : string[] = [
  'main/home',
  'main/admin-loans',
  'main/add-payment/:id',
  'main/admin-clients',
  'main/order-detail/:id'
];

const ADMIN_ROUTES : string[] = [
  'main/statistics',
  'main/admin-clients',
  'main/add-payment/:id',
  'main/admin-collectors',
  'main/admin-orders',
  'main/register-customer',
  'main/register-collector',
  'main/client-detail/:id',
  'main/collector-detail/:id',
  'main/edit-client/:id',
  'main/edit-collector/:id',
  'main/register-order',
  'main/add-clients',
  'main/order-detail/:id'
];

const ACCESS_ROUTES : any = {
  collector: COLLECTOR_ROUTES,
  admin: ADMIN_ROUTES
};
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  /*canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }*/
  private userType : string;

  constructor(private generalService : GeneralService, private router : Router) {}

  canActivate(route : ActivatedRouteSnapshot, state : RouterStateSnapshot) : Observable<boolean> | boolean {
    console.log("asdf");

    this.userType = route.params.type;
    console.log(route.children[0].routeConfig.path);
    const token = sessionStorage.getItem('session_token');
    const url = `${route.url[1]}/${route.children[0].routeConfig.path}`;
    console.log(url);

    console.log(`Tipo de usuario: ${this.userType}`);
    console.log(url);


    if (ACCESS_ROUTES[this.userType] == null) {
      return this.invalidateSession();
    } else if (!ACCESS_ROUTES[this.userType].includes(url)) {
      return this.accessDenied();
    }

    return this.generalService.checkSession(token, this.userType).pipe(tap(response => {
      console.log(response);

      if (!response) {
        this.invalidateSession();
      }
    }));
  }

  private accessDenied() : boolean {
    console.error('Access denied!');
    this.router.navigate([`/${this.userType}`, 'login']);
    return false;
  }

  private invalidateSession() : boolean {
    console.error('Session invalid!');
    this.generalService.showMessage('Sesion terminada', '', 'warning');
    sessionStorage.removeItem('session_token');
    this.generalService.setUserType('');
    this.router.navigate(['/login']);
    return false;
  }

}
